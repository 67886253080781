import { Section, SectionRow } from "../Section";

export class SectionFactory {
  static jsonFactory(rawBody: any): Section[] {
    const objects: Section[] = [];
    for(let rawData of rawBody){
      objects.push(this.jsonFactoryOne(rawData));
    }
    return objects;
 }

 static jsonFactoryOne(raw: any): Section {
    const req = new Section(
      raw.section_id,
      raw.name,
      SectionRowFactory.jsonFactory(raw.section_rows),
    );
    return req;
  }
}

export class SectionRowFactory {
  static jsonFactory(rawBody: any): SectionRow[] {
    const objects: SectionRow[] = [];
    for(let rawData of rawBody){
      objects.push(this.jsonFactoryOne(rawData));
    }
    return objects;
 }

 static jsonFactoryOne(raw: any): SectionRow {
    const req = new SectionRow(
      raw.section_row_id,
      raw.type,
      raw.data_value,
      raw.title,
    );
    return req;
  }
}
