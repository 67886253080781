import { User } from "../user/User";

export class Message {
    public user: User | undefined;
    constructor(
        public messageId: number,
        public threadId: number,
        public userId: number,
        public message: string,
        public timestamp: number
    ){}
}
