import { Component, OnInit, Input, EventEmitter, Output, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ContactPerson } from 'src/app/buisness-object/customer/ContactPerson';
import { InputFiller } from 'src/app/helper/InputFiller';

@Component({
  selector: 'app-customer-create-different-address',
  templateUrl: './customer-create-different-address.component.html',
  styleUrls: ['./customer-create-different-address.component.scss']
})
export class CustomerCreateDifferentAddressComponent implements OnInit, AfterViewInit {
  @Input() form!: FormGroup;
  @Input() submitted!: boolean;
  @Input() contacts!: ContactPerson[];
  @Output() removeDifferentAddressEmitter = new EventEmitter<number>();
  @Output() changeEmitter = new EventEmitter<undefined>();
  public showDropdow = false;
  public formSubscription: Subscription;
  @ViewChild('dropdown_1') dropdown! : ElementRef;
  @ViewChild('inputAddressContact') inputAddressContact : ElementRef;
  public listener!: () => void;

  constructor() { }

  ngOnInit(): void {
    this.formSubscription = this.form.valueChanges.subscribe(change => {
      this.changeEmitter.emit();
      //TODO: changes address
      this.formSubscription.unsubscribe();
    })
  }

  ngOnDestroy(): void {
    if(this.formSubscription) this.formSubscription.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.prepareUpateView();
  }

  prepareUpateView() {
    InputFiller.setUserName(this.inputAddressContact,this.form.controls['contact_address'].value);
  }
}
