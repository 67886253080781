<div class="dialog_view">
  <div class="dialog_window">
    <button class="dialog_window_btn_close" (click)="close()"><img src="../../../../assets/negativewhite.svg"></button>
    <div>
      <div class="dialog_window_header">
        <h2>Projekte</h2>
      </div>
      <div class="dialog_window_body">
        <div *ngFor="let project of projects">
          <div class="checkbox_wrapper" (click)="selectionProject(project)">
            <input type="checkbox" class="checkbox" [checked]="isSelected(project)">
            <label>{{project.awork_project.name}}</label>
          </div>
        </div>
      </div>
    </div>
    <button class="dialog_window_btn_submit" (click)="applyFilter()">Hinzufügen</button>
  </div>
</div>


<!-- <div id="dialog_window">
  <div id="dialog_container">
    <div class="close_icon" (click)="close()">&#10005;</div>
    <label class="lbl_bold_20">Ansprechperson</label>
    <div *ngFor="let project of projects" style="width: 100%;">
      <div class="flex_slider">
        <div class="lbl_regular_12" style="margin-bottom: 0;">{{project}}</div>
        <div class="slider_container" (click)="selectionProject(project)">
          <div class="slider_still" [ngClass]="isSelected(project) ? '' : 'slider_still_on'"></div>
          <div class="slider_move" [ngClass]="isSelected(project) ? 'slider_on' : 'slider_off'"></div>
        </div>
      </div>
    </div>
    <div class="btn_wrapper">
      <button class="btn_tab btn_tab_blue" (click)="null">test</button>
    </div>
  </div>
</div> -->
