import { Project } from "../project/Project";
import { Title } from "./Title";

export class ContactPerson {
  public projects: Project[] = [];
  public isSelected = false;
    constructor(
        public contact_id: number,
        public isPolite: boolean,
        public isMale: boolean,
        public firstName: string,
        public lastName: string,
        public phone: string,
        public email: string,
        public title: Title | undefined,
        public position: string,
        public bill_receiver: boolean,
        public billing_email?: string,
    ){}
}
