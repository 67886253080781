import { LoginComponent } from './view/login/login.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginService } from './service/login/login.service';
import { CustomerComponent } from './view/customer/customer.component';
import { TicketsComponent } from './view/tickets/tickets.component';
import { SuccessComponent } from './view/tickets/success/success.component';
import { DialogService } from './service/dialog/dialog.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'customers',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent,
    data: { isLogin: true }
  },
  {
    path: 'customers',
    component: CustomerComponent,
    canActivate: [LoginService]
  },
  {
    path: 'customers/:customerId',
    component: CustomerComponent,
    canActivate: [LoginService]
  },
  {
    path: 'customers/:customerId/:projectId',
    component: CustomerComponent,
    canActivate: [LoginService]
  },
  {
    path: 'customers/:customerId/:ticketId',
    component: CustomerComponent,
    canActivate: [LoginService]
  },
  {
    path: 'customers/create',
    component: CustomerComponent,
    canActivate: [LoginService]
  },
  {
    path: 'tickets',
    component: TicketsComponent,
    canActivate: [LoginService]
  },
  {
    path: 'tickets/:ticketId',
    component: TicketsComponent,
    canActivate: [LoginService]
  },
  // {
  //   path: 'success',
  //   component: SuccessComponent,
  //   canActivate: [DialogService],
  //   data: { isSuccess: true }
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true,
    paramsInheritanceStrategy: 'always',
    onSameUrlNavigation: 'reload'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
