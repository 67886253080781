<div id="page">
  <div id="hexagons_wrapper">
    <div *ngFor="let row of rows; let i = index" class="row">
      <div *ngFor="let box of row.boxes; let j = index"
        [attr.id]="'hex_'+box.box_id"
        [class.hex]="!box.is_border"
        [class.hex_border]="box.is_border"
        (click)="!failed ? openBox(box, i, j) : null">
        <label *ngIf="!box.is_border">{{box.number}}</label>
      </div>
    </div>
  </div>
</div>
