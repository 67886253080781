export class Section {
  constructor(
    public section_id: number,
    public section_name: string,
    public section_rows: SectionRow[]
  ){}
}

export class SectionRow {
  constructor(
    public section_row_id: number,
    public type: string,
    public data_value: string,
    public title: string
  ){}

  getType(): string {
    if(this.type == 'text') return "text";
    else if(this.type == 'password') return "password";
    else if(this.type == 'number') return "number";
  }

  openUrl() {
    let pattern = '^(https?:\\/\\/)?' + // protocol
        '((([a-zA-Z\\d]([a-zA-Z\\d-]{0,61}[a-zA-Z\\d])*\\.)+' + // sub-domain + domain name
        '[a-zA-Z]{2,13})' + // extension
        '|((\\d{1,3}\\.){3}\\d{1,3})' + // OR ip (v4) address
        '|localhost)' + // OR localhost
        '(\\:\\d{1,5})?' + // port
        '(\\/[a-zA-Z\\&\\d%_.~+-:@]*)*' + // path
        '(\\?[a-zA-Z\\&\\d%_.,~+-:@=;&]*)?' + // query string
        '(\\#[-a-zA-Z&\\d_]*)?$'; // fragment locator
    let regex = new RegExp(pattern);
    if(this.data_value && regex.test(this.data_value)){
      window.open(this.data_value, '_blank').focus();
    } else {
    }
  }
}
