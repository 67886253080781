<div id="login_wrapper">
  <form *ngIf="!showForgotPassword" id="column_1" [formGroup]="form" (ngSubmit)="onSubmit()">
    <h1>Login</h1>
    <div class="input_container">
      <img class="input_icon" src="../../../assets/userimageplaceholderlogin.svg">
      <div class="inputfield_wrapper" [ngClass]="submitted && form.controls['email'].invalid ? 'inputfield_container_invalid' : ''">
        <input type="text" placeholder="E-Mail-Adresse"
          formControlName="email"
          (change)="submitted = false; accessDenied = false;">
        <div class="input_valid_indicator" [ngClass]="form.controls['email'].invalid ? 'input_valid_indicator_invalid' : ''"></div>
      </div>
    </div>
    <div class="input_container">
      <img class="input_icon" src="../../../assets/lock.svg">
      <div class="inputfield_wrapper" [ngClass]="submitted && form.controls['password'].invalid ? 'inputfield_container_invalid' : ''">
        <input type="{{showPassword ? 'text' : 'password'}}" placeholder="Passwort"
          formControlName="password"
          (change)="submitted = false; accessDenied = false;">
          <img class="icon_password" (click)="showPassword = !showPassword" [src]="!showPassword ? '../../../assets/inputhide.svg' : '../../../assets/inputshow.svg'">
        <div class="input_valid_indicator" [ngClass]="form.controls['password'].invalid ? 'input_valid_indicator_invalid' : ''"></div>
      </div>
    </div>
    <div class="btn_container">
      <label [ngStyle]="{'opacity': accessDenied ? '1' : '0'}"  type="button" class="lbl_invalid" style="margin-bottom: 10px;">E-Mail-Adresse oder Passwort falsch.</label>
      <button class="btn_submit" type="submit"  [ngClass]="form.invalid ? 'btn_submit_inactive' : ''" [disabled]="form.invalid" (click)="onSubmit()">Anmelden</button>
      <!-- <button class="btn_label" type="button" (click)="forgotPasswordAction()">Passwort vergessen</button> -->
    </div>
    <div class="footer_container">
      <div class="logo_container">
        <label class="lbl_regular_14">Powered by&nbsp;</label>
        <img class="logo_waymark" src="../../../../assets/waymarklogo-small.svg" (click)="openWebsite()">
      </div>
      <label class="lbl_regular_12 lbl_light">{{version}}</label>
    </div>
  </form>
  <div id="column_2">
    <div class="login_title">
      <div class="waymark_slogen">
        <div class="waymark_slogen_row_1">
            <div class="lbl_explore">Explore</div>
            <div class="text">&nbsp;the</div>
        </div>
        <div class="text">possibilities</div>
      </div>
    </div>
  </div>
  <!-- <app-forgotpassword *ngIf="showForgotPassword"
    (goBackEmitter)="showForgotPassword = false"
  ></app-forgotpassword> -->
</div>
