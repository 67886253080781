import { environment } from './../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, map } from 'rxjs/operators';
import { BehaviorSubject, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  public isPhone$ = new BehaviorSubject<any>(0);

  constructor(
    private http: HttpClient,
    private router: Router,
    ) {}

  public login(username: string, password: string): Observable<boolean> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization : 'Basic ' + btoa(username + ':' + password),
    });
    const observable = this.http.post(environment.api + '/usersession', JSON.stringify([]), { headers });
    return observable.pipe(
      map((result: any) => {
        localStorage.setItem('token', result.token);
        localStorage.setItem('authorization', result.user.authorization);
        localStorage.setItem('user_id', result.user.user_id);
        return true;
      }),
      catchError(error => {
        console.log(error);
        return of(false);
      })
    )
  }

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('authorization');
    localStorage.removeItem('user_id');
    this.router.navigate(['login']);
  }

  canActivate(): boolean {
    if(localStorage.getItem('token')){
      return true;
    } else {
      this.router.navigate(['login']);
      return false;
    }
  }
}
