import { Tag } from "./AworkProject";
import { Section, SectionRow } from "./Section";

export class ProjectType {
  constructor(
    public typ_id: number,
    public typ_name: string,
    public sections: Section[],
    public tags: Tag[]
    // public section_id: number,
    // public section_rows: SectionRow[],
    // public tags: Tag[]
  ){}
}
