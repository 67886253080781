<div class="data_box" *ngIf="false">
  <!-- *ngIf="!project && !selectedThread && !showCreateTicket && !isPhone" -->
  <div class="data_box_package data_box_package_last">
    <div id="customer_ticket_header" *ngIf="this.customer.threads.length > 0">
      <div id="customer_ticket_header_top">
        <label>Tickets: {{customer.threads.length}}</label>
        <input #search id="input_search_tickets" class="inputfield_search lbl_input" type="text" placeholder="Tickets durchsuchen" (input)="searchAction(search.value)" style="width: 280px; padding: 5px 5px 5px 35px">
      </div>
      <div id="customer_ticket_list_titles">
        <label class="lbl_regular_12">Datum / Kunde</label>
        <label class="lbl_regular_12">Ersteller</label>
        <label class="lbl_regular_12">Kommentare</label>
        <label class="lbl_regular_12">Aktionen</label>
        <label class="lbl_regular_12">Status</label>
        <label class="lbl_regular_12"></label>
      </div>
    </div>
    <div id="customer_tickets_list" [class.list_empty]="customer.threads.length == 0">
      <div *ngIf="customer.threads.length == 0" id="empty_threads">
        <img src="../../../../assets/empty.svg">
        <h3 class="lbl_light">Keine Tickets</h3>
      </div>
      <app-customer-ticket-element *ngFor="let thread of customersThreadsFiltered; let last = last"
        [thread]="thread"
        [last]="last"
        [minimizeSidebar]="minimizeSidebar"
        [currentUser]="currentUser"
        (selectionDetailsEmitter)="selectionThreadEmitter.emit($event)"
        (resolveThreadEmitter)="selectionResolveThread($event)"
        (selectionRestoreEmitter)="selectionRestoreThread($event)"
      ></app-customer-ticket-element>
    </div>
  </div>
</div>

<div class="data_box" *ngIf="project &&!selectedThread && !showCreateTicket && !isPhone">
  <div class="data_box_package data_box_package_last">
    <div id="customer_ticket_header" *ngIf="this.project.threads.length > 0">
      <div id="customer_ticket_header_top">
        <label>Tickets: {{project.threads.length}}</label>
        <input #search id="input_search_tickets" class="inputfield_search lbl_input" type="text" placeholder="Tickets durchsuchen" (input)="searchAction(search.value)" style="width: 280px; padding: 5px 5px 5px 35px">
      </div>
      <div id="customer_ticket_list_titles">
        <label class="lbl_regular_12">Datum / Kunde</label>
        <label class="lbl_regular_12">Ersteller</label>
        <label class="lbl_regular_12">Kommentare</label>
        <label class="lbl_regular_12">Aktionen</label>
        <label class="lbl_regular_12">Status</label>
        <label class="lbl_regular_12"></label>
      </div>
    </div>
    <div id="customer_tickets_list" [class.list_empty]="project.threads.length == 0">
      <div *ngIf="project.threads.length == 0" id="empty_threads">
        <img src="../../../../assets/empty.svg">
        <h3 class="lbl_light">Keine Tickets</h3>
      </div>
      <app-customer-ticket-element *ngFor="let thread of customersThreadsFiltered; let last = last"
        [thread]="thread"
        [last]="last"
        [minimizeSidebar]="minimizeSidebar"
        [currentUser]="currentUser"
        [isProject]="project != null"
        (selectionDetailsEmitter)="selectionThreadEmitter.emit($event)"
        (resolveThreadEmitter)="selectionResolveThread($event)"
        (selectionRestoreEmitter)="selectionRestoreThread($event)"
      ></app-customer-ticket-element>
    </div>
  </div>
</div>

<app-tickets-new *ngIf="!project && !selectedThread && !showCreateTicket && !isPhone"
  [users]="users"
></app-tickets-new>

<app-customer-ticket-details *ngIf="selectedThread && !showCreateTicket"
  [thread]="selectedThread"
  [users]="users"
  [currentUser]="currentUser"
  [customer]="customer"
  [isProject]="project != null"
  (selectionCloseThreadEmitter)="selectionBack()"
  (selectionResolveEmitter)="selectionResolveThread($event)"
  (selectionRestoreEmitter)="selectionRestoreThread($event)"
  (successCreateMessageEmitter)="successCreateMessageEmitter.emit($event)"
  (successCreateMessageAndCloseEmitter)="selectionResolveThread($event)"
></app-customer-ticket-details>

<app-customer-ticket-create *ngIf="showCreateTicket"
  [users]="users"
  [customer]="customer"
  [project]="project"
  (selectionBackEmitter)="selectionBack()"
  (closeCreateSuccessEmitter)="closeCreateSuccess($event)"
></app-customer-ticket-create>

<div class="data_box" *ngIf="!project && !selectedThread && !showCreateTicket && isPhone">
  <div class="data_box_package data_box_package_last">
    <div id="customer_ticket_header" *ngIf="this.customer.threads.length > 0">
      <div id="customer_ticket_header_top">
        <label>Tickets: {{customer.threads.length}}</label>
        <input #search class="inputfield_search lbl_input" type="text" placeholder="Tickets durchsuchen" (input)="searchAction(search.value)">
      </div>
    </div>
    <div id="customer_tickets_list" [class.list_empty]="customer.threads.length == 0">
      <div *ngIf="customer.threads.length == 0" id="empty_threads">
        <img src="../../../../assets/empty.svg">
        <h3 class="lbl_light">Keine Tickets</h3>
      </div>
      <app-customer-ticket-element *ngFor="let thread of customersThreadsFiltered; let last = last"
        [thread]="thread"
        [last]="last"
        [minimizeSidebar]="minimizeSidebar"
        [currentUser]="currentUser"
        (selectionDetailsEmitter)="selectionThreadEmitter.emit($event)"
        (resolveThreadEmitter)="selectionResolveThread($event)"
        (selectionRestoreEmitter)="selectionRestoreThread($event)"
      ></app-customer-ticket-element>
    </div>
  </div>
</div>

<div class="data_box" *ngIf="project && !selectedThread && !showCreateTicket && isPhone">
  <div class="data_box_package data_box_package_last">
    <div id="customer_ticket_header" *ngIf="this.project.threads.length > 0">
      <div id="customer_ticket_header_top">
        <label>Tickets: {{project.threads.length}}</label>
        <input #search class="inputfield_search lbl_input" type="text" placeholder="Tickets durchsuchen" (input)="searchAction(search.value)">
      </div>
    </div>
    <div id="customer_tickets_list" [class.list_empty]="project.threads.length == 0">
      <div *ngIf="project.threads.length == 0" id="empty_threads">
        <img src="../../../../assets/empty.svg">
        <h3 class="lbl_light">Keine Tickets</h3>
      </div>
      <app-customer-ticket-element *ngFor="let thread of customersThreadsFiltered; let last = last"
        [thread]="thread"
        [last]="last"
        [minimizeSidebar]="minimizeSidebar"
        [currentUser]="currentUser"
        (selectionDetailsEmitter)="selectionThreadEmitter.emit($event)"
        (resolveThreadEmitter)="selectionResolveThread($event)"
        (selectionRestoreEmitter)="selectionRestoreThread($event)"
      ></app-customer-ticket-element>
    </div>
  </div>
</div>
