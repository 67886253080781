<div class="rich-text-editor-container">
  <div class="rich-text-option-wrapper">
    <button *ngIf="activateBold" [attr.id]="'bold-'+editorId" class="richtext-format-button">
      F
    </button>
    <button *ngIf="activateItalic" [attr.id]="'italic-'+editorId" class="richtext-format-button">
      I
    </button>
    <button *ngIf="activateItalic" [attr.id]="'underline-'+editorId" class="richtext-format-button">
      U
    </button>
    <button *ngIf="activateStrikeThrougth" [attr.id]="'strikethrougth-'+editorId" class="richtext-format-button">
      S
    </button>
  </div>
  <div #richtText [attr.id]="'text-input-'+editorId" class="rich-text-input" contenteditable="true" (paste)="onPaste()"></div>
  <!-- <div class="resize-overlay"></div> -->
</div>
