<div class="data_box" >
  <div class="data_box_package data_box_package_last">
    <div class="ticket_details_header">
      <button class="button_icon_lbl" (click)="selectionBackEmitter.emit()"><img src="../../../../../assets/navarrow.svg" style="rotate: 90deg;">Zurück</button>
    </div>
    <app-custom-mark-input class="mark_input"
      [data]="users"
      [create]="true"
      [btn_name]="'Erstellen'"
      (selectionCreateEmitter)="createThread($event)"
    ></app-custom-mark-input>
  </div>
</div>
