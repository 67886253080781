import { Component, OnInit, Input, EventEmitter, Output} from '@angular/core';
import { ContactPerson } from 'src/app/buisness-object/customer/ContactPerson';

@Component({
  selector: 'app-dialog-contact',
  templateUrl: './dialog-contact.component.html',
  styleUrls: ['./dialog-contact.component.scss']
})
export class DialogContactComponent implements OnInit {
  @Input() contacts!: ContactPerson[];
  @Input() selectedContacts!: ContactPerson[];
  @Output() submitEmitter = new EventEmitter<ContactPerson[]>();
  @Output() closeEmitter = new EventEmitter<undefined>();
  public addedContacts: ContactPerson[] = [];

  constructor() { }

  ngOnInit(): void {
    this.addedContacts = [];
    if(this.selectedContacts && this.selectedContacts.length > 0){
      this.addedContacts = this.selectedContacts;
    }
  }

  close() {
    for(let contact of this.contacts){
      contact.isSelected = false;
    }
    this.closeEmitter.emit();
  }

  onSubmit() {
    this.submitEmitter.emit(this.addedContacts);
  }

  selectionTag(contact: ContactPerson) {
    let i = this.addedContacts.findIndex(c => c.contact_id == contact.contact_id);
    if(i > -1) this.addedContacts.splice(i,1);
    else this.addedContacts.push(contact);
  }
  tagIsSelected(contact: ContactPerson): boolean {
    if(this.addedContacts.findIndex(c => c.contact_id == contact.contact_id) > -1) return true;
    else return false;
  }

  // handleChecking(contact: ContactPerson) {
  //   contact.isSelected = !contact.isSelected;
  //   if(contact.isSelected){
  //     this.addedContacts.push(contact);
  //   } else {
  //     let index = this.addedContacts.findIndex((c: any) => c.contact_id == contact.contact_id);
  //     if(index > -1) this.addedContacts.splice(index,1);
  //   }
  // }
}
