// export enum States {
//   'Nicht gestartet' = 1,
//   'Läuft' = 2,
//   'Abgeschlossen' = 3,
// }

// export enum StatesColorClass {
//   'lbl_box_blue_light' = 1,
//   'lbl_box_yellow' = 2,
//   'lbl_box_gray' = 3,
// }

export enum ENUMVIEWCUSTOMER {
  CUSTOMER = 1,
  PROJECT = 2,
}
export enum ENUMTABCUSTOMER {
  INFORMATION = 1,
  TICKETS = 2,
  BILLING = 3,
  DOCUMENTATION = 4,
}

export enum ENUMSTATUS {
  VALUE2 = "Kunde",
  VALUE1 = "Interessent",
  VALUE3 = "Archiviert",
}

export enum UsedCapicityColorClass {
  'lbl_box_gray' = 1,
  'lbl_box_yellow' = 2,
  'lbl_box_red' = 3,
}

export enum States {
  'not-started' = 1,
  'progress' = 2,
  'stucked' = 3,
  'closed' = 4,
}

export enum StatesColorClass {
  'not-started' = 'lbl_box_blue_light',
  'progress' = 'lbl_box_yellow',
  'stuck' = 'lbl_box_red',
  'closed' = 'lbl_box_green',
}
