import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Thread } from 'src/app/buisness-object/thread/Thread';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dialog-open-notifications',
  templateUrl: './dialog-open-notifications.component.html',
  styleUrls: ['./dialog-open-notifications.component.scss']
})
export class DialogOpenNotificationsComponent implements OnInit {
  @Input() tickets: Thread[];
  @Input() show: boolean;
  @Output() openTicketEmitter = new EventEmitter<Thread>();
  @Output() closeDialogEmitter = new EventEmitter<boolean>();

  constructor(
    private router: Router,
  ) {}

  ngOnInit(): void {
  }

  openTicket(thread: Thread) {
    thread.notifyOpen = false;
    this.router.navigate(['customers'], { queryParams: { customerId: thread.customerId, ticketId: thread.threadId } });
    this.show = false;
  }
}
