import { Component, OnInit, OnChanges, OnDestroy, SimpleChanges, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Subscription } from 'rxjs';
import { Customer } from 'src/app/buisness-object/customer/Customer';
import { Message } from 'src/app/buisness-object/thread/Message';
import { Thread } from 'src/app/buisness-object/thread/Thread';
import { User } from 'src/app/buisness-object/user/User';
import { CustomerService } from 'src/app/service/customer/customer.service';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { ThreadService } from 'src/app/service/thread/thread.service';
import { UserService } from 'src/app/service/user/user.service';
import { Location } from '@angular/common';

interface Filter {
  filter_ticket_typ: number,
  filter_after: number,
}

@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.scss']
})
export class TicketsComponent implements OnInit, OnChanges, OnDestroy  {
  public auth = Number(localStorage.getItem('authorization'));
  public amountAllCustomers = 0;
  public currentUser!: User;
  public users: User[] = [];
  public customers: Customer[] = [];
  public threads: Thread[] = [];
  public threadsFiltered: Thread[] = [];
  public threadsTemp: Thread[] = [];
  public threadsSortByCustomers!: Thread[][];
  public notifyThreads: Thread[] = [];
  public allThreads: Thread[] = [];
  public filter: Filter = {
    filter_ticket_typ: 1,
    filter_after: 1
  }
  public selectedThread: Thread | undefined;
  public routeSub!: Subscription;
  public showList = false;
  public showListItems: boolean[] = [];
  public isLoaded = false;
  public showDropdowOne = false;
  @ViewChild('dropdown_1') dropdown_1! : ElementRef;
  @ViewChild('inputFilter') inputFilter! : ElementRef;
  public listener!: () => void;
  public surface = 1;
  public routeCustomerId!: number;
  public inputTimeout;


  constructor(
    private thService: ThreadService,
    private uService: UserService,
    private cService: CustomerService,
    private router: Router,
    private activateRoute: ActivatedRoute,
    private locationRoute: Location,
    private dService: DialogService,
    private renderer: Renderer2
    ) { }

  ngOnInit(): void {
    this.getDataServerside();
  }

  ngAfterViewInit(): void {
    this.listener = this.renderer.listen('window','click',(e:Event) => {
      if(this.showDropdowOne && !this.dropdown_1.nativeElement.contains(e.target)) this.showDropdowOne = false;
    });
    if(this.inputFilter) this.inputFilter.nativeElement.value = 'Offene Tickets';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['threadsSortByCustomers']){
      this.showListItems = [];
      if(this.threadsSortByCustomers){
        if(this.threadsSortByCustomers.length != 0){
          this.threadsSortByCustomers.forEach(item => {
            this.showListItems.push(true);
          });
        }
      }
    }
  }

  ngOnDestroy(): void {
    if(this.routeSub) this.routeSub.unsubscribe();
    if(this.listener) this.listener();
    if(this.inputTimeout) clearTimeout(this.inputTimeout);
  }

  getDataServerside() {
    forkJoin({
      allThreads: this.thService.getAllThreads(),
      users: this.uService.getUsers(),
      customers: this.cService.getCustomers(),
    }).subscribe((result) => {
      if(result){
        this.thService.getNotifications().subscribe((notifyThreads) => {
          this.notifyThreads = notifyThreads;
          this.threads = result.allThreads;
          this.users = result.users;
          this.customers = result.customers;
          this.setAndMergeData();
          this.filterThreads();
          this.setListeners();
          this.isLoaded = true;
        });
      }
    })
  }

  setListeners() {
    let params = this.activateRoute.snapshot.queryParams;
    if(params['customerId']){
      this.routeCustomerId = params['customerId'];
      this.surface = 2;
    }
    this.routeSub = this.activateRoute.params.subscribe((params) => {
      if(params['ticketId']){
        this.locationRoute.replaceState('/tickets?ticketId=' + params['ticketId']);
        for(let thread of this.threads){
          if(thread.threadId == Number(params['ticketId'])){
            this.openThread(thread);
            break;
          }
        }
      }
    })
  }

  setAndMergeData() {
    for(let user of this.users){
      if(user.userId == Number(localStorage.getItem('user_id'))){
        this.currentUser = user;
        break;
      }
    }
    for(let user of this.users){
      for(let thread of this.threads){
        if(thread.userId == user.userId){
          thread.user = user;
        }
      }
    }
    for(let user of this.users){
      for(let thread of this.threads){
        for(let message of thread.messages){
          if(message.userId == user.userId){
            message.user = user;
          }
        }
      }
    }
    for(let thread of this.threads){
      for(let customer of this.customers){
        if(thread.customerId == customer.customer_id){
          thread.customer = customer;
          break;
        }
      }
    }
    this.threadsFiltered = this.threads;
    this.threadsTemp = this.threads;
  }

  filterThreads() {
    this.selectedThread = undefined;
    this.threadsSortByCustomers = new Array<Array<Thread>>();
    this.threadsFiltered = [];
    for(let t of this.threads){
      if(this.filter.filter_ticket_typ == 1 && !t.resolved){ //offene
        this.threadsFiltered.push(t);
      } else if(this.filter.filter_ticket_typ == 2 && t.resolved){ //gelöste
        this.threadsFiltered.push(t);
      }
    }
    if(this.filter.filter_after == 1){
      this.threadsFiltered.sort((a,b) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime());
    }
    if(this.filter.filter_after == 2){
      let customers: Customer[] = [];
      for(let thread of this.threadsFiltered){
        let duplicate = false;
        for(let customer of customers){
          if(customer.customer_id == thread.customerId){
            duplicate = true;
            break;
          }
        }
        if(!duplicate){
          customers.push(thread.customer!);
        }
      }
      customers.sort((a,b) => {
        if(a.company_name < b.company_name) {
          return -1;
        }
        if(a.company_name > b.company_name) {
          return 1;
        }
        return 0;
      });
      for(let customer of customers){
        let threadsOneCustomer: Thread[] = [];
        if(customer){
          for(let thread of this.threadsFiltered){
            if(thread.customerId == customer.customer_id){
              threadsOneCustomer.push(thread);
            }
          }
          if(threadsOneCustomer.length != 0){
            this.threadsSortByCustomers.push(threadsOneCustomer);
          }
        }
      }
    }
    if(this.filter.filter_after == 3){
      let users: User[] = [];
      for(let thread of this.threadsFiltered){
        let duplicate = false;
        for(let user of users){
          if(user.userId == thread.userId){
            duplicate = true;
            break;
          }
        }
        if(!duplicate){
          users.push(thread.user!);
        }
      }
      users.sort((a,b) => {
        if(a.firstName < b.firstName) {
          return -1;
        }
        if(a.firstName > b.firstName) {
          return 1;
        }
        return 0;
      })
      for(let user of users){
        let threads: Thread[] = [];
        if(user){
          for(let thread of this.threadsFiltered){
            if(thread.userId == user.userId){
              threads.push(thread);
            }
          }
          if(threads.length != 0){
            this.threadsSortByCustomers.push(threads);
          }
        }
      }
    }
  }

  updateCustomerThread(thread: Thread) {
    this.thService.updateThread(thread).subscribe((thread) => {
      if(thread) { }
    });
  }

  gotoHome() {
    this.router.navigate(['customer/0']);
  }

  changeFilter(filter: number) {
    this.filter.filter_after = filter;
    this.filterThreads();
  }

  changeFilterTyp(typ: number) {
    this.filter.filter_ticket_typ = typ;
    this.filterThreads();
  }

  resolveThread(thread: Thread) {
    thread.status = 2;
    thread.resolved = new Date().getTime();
    thread.resolved_by_user_id = this.currentUser.userId;
    this.thService.updateThread(thread).subscribe((thread) => {
      if(thread){
        let index = this.threads.findIndex(t => t.threadId == thread.threadId);
        if(index != -1) this.threads.splice(index,1);
        let indexDeep = this.threadsFiltered.findIndex(t => t.threadId == thread.threadId);
        if(indexDeep != -1) this.threads.splice(indexDeep,1);
        this.dService.showNotification({
          title: this.dService.getTitle(),
          message: 'Ticket aufgelöst.',
          success: true
        });
        this.selectedThread = undefined;
      }
    })
  }

  restoreThread(thread: Thread) {

  }

  getRandomNumber(min: number, max:number): number {
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  openThread(thread: Thread) {
    if(thread.notifyOpen){
      thread.notifyOpen = false;
      this.thService.updateThread(thread).subscribe((t) => {
        if(t){
          for(let th of this.threads){
            if(th.threadId == thread.threadId){
              th.notifyOpen = false;
              break;
            }
          }
        }
      })
    }
    this.selectedThread = thread;
  }

  openDetails(thread: Thread) {
    this.locationRoute.replaceState('/tickets?ticketId=' + thread.threadId);
    this.selectedThread = thread;
  }

  createMessage(msg: Message) {
  }

  closeThread() {
    this.selectedThread = undefined;
    this.locationRoute.replaceState('/tickets');
    this.surface = 1;
  }

  selectionSearch(value: string) {
    if(this.inputTimeout) clearTimeout(this.inputTimeout);
    this.inputTimeout = setTimeout(() => {
      this.searchAction(value);
    }, 300);
  }

  searchAction(value: string) {
    this.filter.filter_after = 1;
    this.threadsFiltered = this.threadsTemp.filter((th) => {
      if(value && value.length > 2){
        this.threadsFiltered = [];
        return th.customer!.company_name.toLowerCase().includes(value.toLowerCase()) && (this.filter.filter_ticket_typ == 1 ? !th.resolved : th.resolved) ||
              th.user!.firstName.toLowerCase().includes(value.toLowerCase()) && (this.filter.filter_ticket_typ == 1 ? !th.resolved : th.resolved) ||
              th.user!.lastName.toLowerCase().includes(value.toLowerCase()) && (this.filter.filter_ticket_typ == 1 ? !th.resolved : th.resolved) ||
              th.threadId.toString().toLowerCase().includes(value.toLowerCase()) && (this.filter.filter_ticket_typ == 1 ? !th.resolved : th.resolved) ||
              th.messages[0]?.message.toLowerCase().includes(value.toLowerCase()) && (this.filter.filter_ticket_typ == 1 ? !th.resolved : th.resolved);
      } else {
        return this.threadsTemp;
      }
    })
    if(!value || value.length == 0){
      this.filterThreads();
    }
  }

  showListAction(index: number) {
    this.showListItems[index] = !this.showListItems[index];
  }

  isShown(index: number): boolean {
    return this.showListItems[index];
  }

  openCreate() {
    this.surface = 2;
  }

  closeCreate(thread: Thread) {
    if(this.routeCustomerId){
      this.router.navigate(['customers', this.routeCustomerId]);
    } else {
      this.threads.push(thread);
      this.filterThreads();
      this.surface = 1;
    }
  }

  // goToCustomer() {
  //   this.router.navigate(['customers', this.routeCustomerId])
  // }

  goToCustomer() {
    this.router.navigate(['customers'], { queryParams: { customerId: this.selectedThread.customer!.customer_id } });
  }
}
