import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UserFactory } from 'src/app/buisness-object/user/factory/UserFactory';
import { User } from 'src/app/buisness-object/user/User';
import { environment } from 'src/environments/environment';
import { DialogService } from '../dialog/dialog.service';
import { LoginService } from '../login/login.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(
    private http: HttpClient,
    private lService: LoginService,
    private dialogService: DialogService
    ) { }

  getUsers(): Observable<User[]> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    let start = new Date();
    const observable = this.http.get(environment.api + '/back/users', { headers });
    return observable.pipe(
      map((rawUsers: any) => {
        console.log(`Request time users: ${(new Date().getTime()-start.getTime())/1000}`);
        const users: User[] = UserFactory.jsonFactory(rawUsers.user);
        return users;
      }),
      catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          this.dialogService.handleError(error);
          console.log(error);
        }
        return [];
      })
    )
  }
}
