<div id="ticket_details_wrapper">
  <div id="ticket_create_header">
    <div class="input_wrapper">
      <div class="input_wrapper">
        <label class="lbl_regular_12">Kunde</label>
        <div class="dropdown_wrapper" (click)="showDropdownOne = !showDropdownOne" appDropdown [show]="showDropdownOne" (changedValueEmitter)="showDropdownOne = $event">
          <input #inputCustomer placeholder="Auswählen..." [required]="submitted && form.controls['customer'].invalid" (input)="filterCustomers(inputCustomer.value)">
          <img class="dropdown_icon" [ngClass]="showDropdownOne ? 'dropdown_icon_open' : ''" src="../../../../assets/arrowdropdown.svg">
          <div class="dropdown_value_container" *ngIf="showDropdownOne">
            <div *ngFor="let customer of customersFiltered">
              <div (click)="form.controls['customer'].setValue(customer); inputCustomer.value = (customer.company_name)" class="dropdown_element lbl_dropdown_small">{{customer.company_name}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!form.controls['customer'].value"></div>
    <div id="customer_details" *ngIf="form.controls['customer'].value">
      <label class="lbl_light_12" style="margin-bottom: 10px;">WMPD {{form.controls['customer'].value.customer_id}}</label>
      <label class="lbl_bold_20" style="margin-bottom: 10px;">{{form.controls['customer'].value.company_name}}</label>
      <label class="lbl_light_12">{{form.controls['customer'].value.contact_persons[0].email}}</label>
      <label class="lbl_light_12">{{form.controls['customer'].value.contact_persons[0].phone}}</label>
      <div *ngIf="form.controls['customer'].value.website_url" (click)="form.controls['customer'].value.openUrl()" class="url_wrapper">
          <label class="lbl_light_12" style="margin-right: 5px;">{{form.controls['customer'].value.website_url}}</label>
          <a class="go-to-web">
              <img class="img-go-to-web" src="../../../../assets/icon-go-to-web.png">
          </a>
      </div>
    </div>
  </div>
  <app-custom-mark-input class="mark_input"
    [data]="users"
    [btn_name]="'Erstellen'"
    (selectionCreateEmitter)="createMessage($event)"
  ></app-custom-mark-input>
</div>
