import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { Subscription, concat, interval } from 'rxjs';
import { first, tap, filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UpdateService {

  updateSubscription: Subscription;

  constructor(
    private appRef: ApplicationRef,
    private swUpdates: SwUpdate
  ) {
    //Allow the app to stabilize first, before starting
    //polling for updates with `interval()`.
    if(environment.production){
      const appIsStable$ = appRef.isStable.pipe(first(isStable => { return isStable; }));
      const interval$ = interval(6 * 60 * 60).pipe(tap(() => {}));
      const intervalOnceAppIsStable$ = concat(appIsStable$, interval$);
      intervalOnceAppIsStable$.subscribe(async () => {
        try {
          const updateFound = await swUpdates.checkForUpdate();
          console.log(updateFound ? 'A new version is available.' : 'Already on the latest version.');
          if(updateFound){
            this.swUpdates?.versionUpdates.subscribe((update) => {
              if(update){
                this.promptUser();
              }
            });
          }
        } catch (err) {
          console.error('Failed to check for updates:', err);
        }
      });
    }

    // this.updateSubscription = this.swUpdates?.versionUpdates
    // if(swUpdates.isEnabled) {
    //   interval(1 * 60 * 60).subscribe(() => {
    //     console.log('checking for updates');
    //     this.checkForUpdates();
    //     // this.swUpdates?.checkForUpdate().then((update) => {
    //     //   if(update){
    //     //     console.log('checking for updates');
    //     //     this.swUpdates.available
    //     //     //this.promptUser();
    //     //   }
    //     // })
    //   });
    // }
  }

  public checkForUpdates() {
    this.updateSubscription = this.swUpdates?.versionUpdates.subscribe((event) => {
      switch(event.type) {
        case "VERSION_DETECTED":
            console.log("VERSION_DETECTED: ", event.version);
            break;
        case "VERSION_READY":
            console.log("VERSION_READY: ");
            console.log("current version: ", event.currentVersion);
            console.log("new version: ", event.latestVersion);
            break;
        case "VERSION_INSTALLATION_FAILED":
            console.log("VERSION_INSTALLATION_FAILED: ", event);
            break;
      }
    });
    if(this.swUpdates.isEnabled) {
      this.swUpdates.activateUpdate();
      interval(1 * 60 * 60).subscribe(() => {
        console.log('checking for updates');
      });
    }
  }

  // public reloadSite(): void {
  //   window.location.reload();
  // }

  // public checkForUpdates() {
  //   this.swUpdates?.versionUpdates.subscribe((update) => {
  //     console.log(update)
  //     if(update){
  //       this.promptUser();
  //     }
  //   })
  // }

  // public activateUpdate(): void {
  //   this.swUpdates?.activateUpdate();
  // }

  private promptUser(): void {
    console.log('updating to new version');
    this.swUpdates?.activateUpdate().then(() => window.location.reload());
  }
}
