<form *ngIf="form" class="view_body_create" [formGroup]="form">
  <div id="customer_create_header">
    <h1>Kunden {{isEdit ? 'bearbeiten' : 'anlegen'}}</h1>
    <div id="customer_create_button_wrapper">
      <button class="btn_cancel" (click)="selectionCancel()">Abbrechen</button>
      <button class="btn_tab btn_tab_blue_light" (click)="customer ? selectionUpdate() : selectionCreate()">{{customer ? 'Speichern' : 'Anlegen'}}</button>
    </div>
  </div>
  <div id="customer_create_container">
    <div class="column">
      <div class="data_box">
        <div class="data_box_package data_box_package_last">
          <h2>Status</h2>
          <div class="input_wrapper">
            <label class="lbl_regular_12">Kundenstatus *</label>
            <div class="dropdown_wrapper" (click)="showDropdowSeven = !showDropdowSeven" appDropdown [show]="showDropdowSeven" (changedValueEmitter)="showDropdowSeven = $event">
              <input #inputStatus placeholder="Auswählen..." [required]="submitted && form.controls['typ'].invalid" data-readonly>
              <img class="dropdown_icon" [ngClass]="showDropdowSeven ? 'dropdown_icon_open' : ''" src="../../../../assets/arrowdropdown.svg">
              <div class="dropdown_value_container" *ngIf="showDropdowSeven">
                <label (click)="form.controls['typ'].setValue(1); inputStatus.value = 'Kunde'" class="dropdown_element lbl_dropdown_small">Kunde</label>
                <label (click)="form.controls['typ'].setValue(2); inputStatus.value = 'Interessent'" class="dropdown_element lbl_dropdown_small">Interessent</label>
                <label (click)="form.controls['typ'].setValue(3); inputStatus.value = 'Archiviert'" class="dropdown_element lbl_dropdown_small">Archiviert</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="data_box">
        <div class="data_box_package">
          <h2>Unternehmen</h2>
          <div class="input_wrapper">
            <label for="company_name">Name des Unternehmens *</label>
            <input type="text" placeholder="Name des Unternehmens" formControlName="company_name" [required]="submitted && form.controls['company_name'].invalid">
          </div>
          <div class="input_wrapper">
            <label for="website_url">Website</label>
            <input type="text" placeholder="Website" formControlName="website_url" [class.input_invalid]="(form.controls['website_url'].errors?.invalidlink)">
          </div>
        </div>
        <div class="data_box_package data_box_package_last">
          <h2>Rechnungsadresse</h2>
          <div class="input_wrapper">
            <label for="street">Firmenname *</label>
            <!-- <input type="text" placeholder="Firmenname" formControlName="billing_company_name" [class.input_invalid]="submitted && form.controls['billing_company_name'].invalid"> -->
            <textarea placeholder="Firmenname *" maxlength="250" formControlName="billing_company_name" [required]="submitted && this.form.controls['billing_company_name'].invalid"></textarea>
          </div>
          <div class="input_wrapper">
            <label for="atu">ATU Nummer</label>
            <input type="text" placeholder="ATU" formControlName="atu" [required]="submitted && form.controls['atu'].invalid">
          </div>
          <div class="input_wrapper">
            <label for="street">Straße *</label>
            <input type="text" placeholder="Straße" formControlName="street" [class.input_invalid]="submitted && form.controls['street'].invalid">
          </div>
          <div class="grid_horizontal">
            <div class="input_wrapper">
              <label for="postal_code">PLZ *</label>
              <input type="text" placeholder="PLZ" formControlName="postal_code" [class.input_invalid]="submitted && form.controls['postal_code'].invalid">
            </div>
            <div class="input_wrapper">
              <label for="city">ORT *</label>
              <input type="text" placeholder="ORT" formControlName="city" [class.input_invalid]="submitted && form.controls['city'].invalid">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="column">
      <div class="data_box">
        <div class="data_box_package data_box_package_last">
          <h2>Seller-Informationen</h2>
          <div class="input_wrapper">
            <label class="lbl_regular_12">Seller *</label>
            <div class="dropdown_wrapper" (click)="showDropdowFour = !showDropdowFour" appDropdown [show]="showDropdowFour" (changedValueEmitter)="showDropdowFour = $event">
              <input #inputSeller placeholder="Auswählen..." [class.input_invalid]="submitted && form.controls['seller'].invalid" data-readonly>
              <img class="dropdown_icon" [ngClass]="showDropdowFour ? 'dropdown_icon_open' : ''" src="../../../../assets/arrowdropdown.svg">
              <div class="dropdown_value_container" *ngIf="showDropdowFour">
                <div *ngFor="let user of users">
                  <label *ngIf="user.isSeller" (click)="form.controls['seller'].setValue(user); inputSeller.value = (user.firstName + ' ' + user.lastName)" class="dropdown_element lbl_dropdown_small">{{user.firstName}} {{user.lastName}}</label>
                </div>
              </div>
            </div>
          </div>
          <div class="grid_horizontal_3">
            <div class="input_wrapper">
              <label class="lbl_regular_12">Hat Bewertung</label>
              <div #dropdown_5 class="dropdown_wrapper" (click)="showDropdowFive = !showDropdowFive" appDropdown [show]="showDropdowFive" (changedValueEmitter)="showDropdowFive = $event">
                <input #inputRating class="dropdown_btn lbl_dropdown_small" placeholder="Auswählen..." readonly data-readonly
                      [value]="form.controls['has_rating'].value == true ? 'Ja' : (form.controls['has_rating'].value == false ? 'Nein' : '')">
                <img class="dropdown_icon" [ngClass]="showDropdowFive ? 'dropdown_icon_rotate' : ''" src="../../../../assets/arrowdropdown.svg">
                <div *ngIf="showDropdowFive" class="dropdown_value_container">
                  <div class="dropdown_value_container_scroll_wrapper">
                    <div (click)="form.controls['has_rating'].setValue(true)" class="dropdown_element lbl_dropdown_small">Ja</div>
                    <div (click)="form.controls['has_rating'].setValue(false)" class="dropdown_element lbl_dropdown_small">Nein</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="input_wrapper">
              <label class="lbl_regular_12">Datenschutz vorhanden</label>
              <div #dropdown_6 class="dropdown_wrapper" (click)="showDropdowSix = !showDropdowSix" appDropdown [show]="showDropdowSix" (changedValueEmitter)="showDropdowSix = $event">
                <input #inputPrivacy class="dropdown_btn lbl_dropdown_small" placeholder="Auswählen..." readonly data-readonly
                    [value]="form.controls['data_privacy_available'].value == true ? 'Ja' : (form.controls['data_privacy_available'].value == false ? 'Nein' : '')">
                <img class="dropdown_icon" [ngClass]="showDropdowSix ? 'dropdown_icon_rotate' : ''" src="../../../../assets/arrowdropdown.svg">
                <div *ngIf="showDropdowSix" class="dropdown_value_container">
                  <div class="dropdown_value_container_scroll_wrapper">
                    <div (click)="form.controls['data_privacy_available'].setValue(true);" class="dropdown_element lbl_dropdown_small">Ja</div>
                    <div (click)="form.controls['data_privacy_available'].setValue(false);" class="dropdown_element lbl_dropdown_small">Nein</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="input_wrapper">
              <label class="lbl_regular_12">Letzter Termin</label>
              <input #inputDate1 type="date" [min]="minDate" [max]="maxDate" formControlName="last_appointment" [class.date-input--has-value]="inputDate1.value != null" [class.input_invalid]="submitted && form.controls['last_appointment'].errors">
            </div>
          </div>
        </div>
      </div>
      <div class="data_box">
        <app-customer-create-contact *ngFor="let conatactForm of form.controls['contacts'].value; let last = last; let i = index"
          [form]="conatactForm"
          [submitted]="submitted"
          [titles]="titles"
          [last]="last"
          [index]="i"
          [isEdit]="customer ? true : false"
          [customer]="customer"
          (removeContactEmitter)="removeContactForm(i)"
          (seclectionBillReceiverEmitter)="selectionBillReceiver(i)"
          (changeEmitter)="changeDetected = true"
        ></app-customer-create-contact>
      </div>
      <button class="btn_add_field" (click)="selectionAddContact()"><img class="icon_add" src="../../../../assets/addelement.svg"> Kontaktperson hintzufügen</button>
    </div>
    <div class="column">
      <div *ngIf="form">
        <app-section *ngFor="let sectionForm of form.controls['sections'].value; let i = index; let last = last" style="width: 100%"
            [form]="sectionForm"
            [submitted]="submitted"
            [last]="last"
            [index]="i"
            (changeEmitter)="changeDetected = true"
        ></app-section>
      </div>
      <!-- <div class="data_box" *ngIf="!isEdit">
        <div class="data_box_package data_box_package_last">
          <h2>Anmerkungen</h2>
          <label class="lbl_regular_12">Fügen Sie einen Kommentar hinzu.</label>
          <div *ngIf="message" style="margin-top: 10px;">
            <div class="lbl_regular_12">
                <div [innerHTML]="message.message"></div>
            </div>
          </div>
          <div class="btn_label" *ngIf="message" (click)="message = undefined">Anmerkung entfernen</div>
          <div class="message_box_wrapper">
            <div contenteditable #textinput id="textinput" class="message_box" autocomplete="off" (input)='valueChanges(textinput, $event)' (paste)="onPaste($event)"></div>
            <div id="dropdown_user_container" class="dropdown_value_container" style="width: fit-content;" [style.top.px]="posTopUserBox" [style.left.px]="posLeftUserBox" *ngIf="showDropDownUser">
              <div class="dropdown_value_container_scroll_wrapper" id="dropdown_message_box_user">
                <div *ngFor="let user of filterdUsers; let i = index" [attr.id]="'user_'+i" class="dropdown_element lbl_dropdown_small" [ngClass]="placeArrowIndex == i ? 'dropdown_element_active' : ''" (click)="userSelected(user)">{{user.firstName}} {{user.lastName}}</div>
              </div>
            </div>
          </div>
          <button class="btn_tab btn_tab_blue" style="align-self: flex-end; margin-bottom: 10px; width: fit-content;" (click)="onSubmitMessage()" [ngClass]="previousText.length == 0 ? 'button-send-answer-disabled' : ''" [disabled]="previousText.length == 0">
            Kommentart hintzufügen
            <img src="../../../../assets/send.svg">
          </button>
        </div>
      </div> -->
    </div>
  </div>
</form>


