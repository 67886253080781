<div id="dialog_window" *ngIf="tickets">
    <div #notify_dialog id="dialog_container" appDropdown [show]="show" [excludingElementIds]="['btn_open_notify']" (changedValueEmitter)="closeDialogEmitter.emit($event)">
      <label class="lbl_bold_20">Benachrichtigungen {{'('+tickets.length+')'}}</label>
      <div id="notify_dialog_body">
        <div class="notify_element_wrapper" *ngFor="let thread of tickets; let last = last" (click)="openTicketEmitter.emit(thread)"
            [ngStyle]="{'border-color': last ? 'transparent' : ''}">

            <div class="column_1">
              <label class="lbl_customer_data_box_data">Nr. {{thread.threadId}}</label>
              <label class="lbl_regular_14">{{thread.customer!.company_name}}</label>
              <div class="inner_flex">
                <img class="user_img_container"
                        [ngClass]="thread.user!.picture ? 'user_img' : 'user_img_placeholder'"
                        [src]="thread.user.getAvatar()">
                <div>
                  <label class="lbl_light">{{thread.user!.firstName}} {{thread.user!.lastName}}</label>
                  <label class="lbl_message" [innerHTML]="thread.getThreadTitle(false ? 150 : 90)"></label>
                </div>
              </div>
            </div>
            <img class="icon_arrow_right" src="../../../../assets/arrowdropdown.svg">
          </div>
      </div>
    </div>
  </div>
