<div class="data_box">
  <div class="data_box_package data_box_package_last">
    <div class="ticket_details_header">
      <!-- <button class="button_icon_lbl" (click)="selectionCloseThreadEmitter.emit()"><img src="../../../../../assets/navarrow.svg" style="rotate: 90deg;">Zurück</button> -->
      <a class="button_icon_lbl" routerLink="/customers" [queryParams]="routeParams">
        <img src="../../../../../assets/navarrow.svg" style="rotate: 90deg;">
        Zurück
      </a>
      <label *ngIf="thread.resolved && thread.resolved_by_user" class="lbl_light">Aufgelöst von: {{thread.resolved_by_user.firstName + ' ' + thread.resolved_by_user.lastName}}</label>
    </div>
    <div class="ticket_details_body">
      <img class="user_img_container"
        [ngClass]="thread.user!.picture ? 'user_img' : 'user_img_placeholder'"
        [src]="thread.user!.picture ? thread.user!.picture : '../../assets/userimageplaceholder.svg'">
      <div class="ticket_details_body_main">
        <div class="ticket_details_body_header">
          <div>
            <label class="lbl_bold">{{thread.user!.firstName}} {{thread.user!.lastName}}</label>
            <label >{{thread.createdDate | date: 'dd. MMM YYYY, HH:mm'}}</label>
          </div>
          <div class="ticket_details_body_header_inner">
            <div class="ticket_details_body_header_button_wrapper">
              <button *ngIf="(thread.userId != currentUser.userId && customer.seller.userId != currentUser.userId) && !thread.subscribe" class="button_icon_lbl" (click)="selectionSubscribe(true)"><img class="icon_resolve" src="../../../../../assets/notification-blue.svg">Subscribe</button>
              <button *ngIf="(thread.userId != currentUser.userId && customer.seller.userId != currentUser.userId) && thread.subscribe" class="button_icon_lbl" (click)="selectionSubscribe(false)"><img class="icon_resolve" src="../../../../../assets/notification-blue.svg">Unsubscribe</button>
              <button *ngIf="!thread.resolved" class="button_icon_lbl" (click)="selectionResolveEmitter.emit(thread)"><img class="icon_resolve" src="../../../../assets/clear.svg">Auflösen</button>
              <button *ngIf="thread.resolved" class="button_icon_lbl" (click)="selectionRestoreEmitter.emit(thread)"><img class="icon_resolve" src="../../../../assets/direction.svg">Wiederherstellen</button>
            </div>
            <label class="lbl_color_box" [ngClass]="thread.resolved ? 'lbl_color_box_green' : 'lbl_color_box_red'">{{thread.resolved ? 'Erledigt' : 'offen'}}</label>
          </div>
        </div>
        <div class="lbl_customer_data_box_data lbl_message" [innerHTML]="thread.getThreadTitle(0)"></div>
        <div id="ticket_answer_list">
          <label>Antworten</label>
          <div *ngFor="let msg of thread.messages | slice:1" class="ticket_answer_wrapper">
            <div class="ticket_details_body">
              <img class="user_img_container"
                [ngClass]="msg.user?.picture ? 'user_img' : 'user_img_placeholder'"
                [src]="msg.user?.picture ? msg.user.picture : '../../assets/userimageplaceholder.svg'">
              <div class="ticket_details_body_main_answer">
                <div class="ticket_details_answer_header">
                  <label class="lbl_bold">{{msg.user!.firstName}} {{msg.user!.lastName}}</label>
                  <label >{{msg.timestamp | date: 'dd. MMM YYYY, HH:mm'}}</label>
                </div>
                <div class="lbl_customer_data_box_data lbl_message" [innerHTML]="msg.message"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div></div>
      <app-custom-mark-input *ngIf="!thread.resolved"
        class="mark_input"
        [data]="users"
        [btn_name]="'Antworten'"
        (selectionCreateEmitter)="createMessage($event, false)"
        (selectionCreateAndCloseEmitter)="createMessage($event, true)"
      ></app-custom-mark-input>
    </div>
  </div>
</div>


<!-- <div class="data_box" *ngIf="isPhone">
  <div class="data_box_package data_box_package_last">
    <div class="ticket_details_header">
      <button class="button_icon_lbl" (click)="selectionCloseThreadEmitter.emit()"><img src="../../../../../assets/navarrow.svg" style="rotate: 90deg;">Zurück</button>
      <label *ngIf="thread.resolved && thread.resolved_by_user" class="lbl_light">Aufgelöst von: {{thread.resolved_by_user.firstName + ' ' + thread.resolved_by_user.lastName}}</label>
    </div>
    <div class="ticket_details_body">
      <img class="user_img_container"
        [ngClass]="thread.user!.picture ? 'user_img' : 'user_img_placeholder'"
        [src]="thread.user!.picture ? thread.user!.picture : '../../assets/userimageplaceholder.svg'">
      <div class="ticket_details_body_main">
        <div class="ticket_details_body_header">
          <div>
            <label class="lbl_bold">{{thread.user!.firstName}} {{thread.user!.lastName}}</label>
            <label >{{thread.createdDate | date: 'dd. MMM YYYY, HH:mm'}}</label>
          </div>
          <div class="ticket_details_body_header_inner">
            <div class="ticket_details_body_header_button_wrapper">
              <button *ngIf="!thread.subscribe" class="button_icon_lbl" (click)="selectionSubscribe(true)"><img class="icon_resolve" src="../../../../../assets/notification-blue.svg">Subscribe</button>
              <button *ngIf="thread.subscribe" class="button_icon_lbl" (click)="selectionSubscribe(false)"><img class="icon_resolve" src="../../../../../assets/notification-blue.svg">Unsubscribe</button>
              <button *ngIf="!thread.resolved" class="button_icon_lbl" (click)="selectionResolveEmitter.emit(thread)"><img class="icon_resolve" src="../../../../assets/clear.svg">Auflösen</button>
              <button *ngIf="thread.resolved" class="button_icon_lbl" (click)="selectionRestoreEmitter.emit(thread)"><img class="icon_resolve" src="../../../../assets/direction.svg">Wiederherstellen</button>
            </div>
            <label class="lbl_color_box" [ngClass]="thread.resolved ? 'lbl_color_box_green' : 'lbl_color_box_red'">{{thread.resolved ? 'Erledigt' : 'offen'}}</label>
          </div>
        </div>
        <div class="lbl_customer_data_box_data lbl_message" [innerHTML]="thread.getThreadTitle(0)"></div>
        <div id="ticket_answer_list">
          <label>Antworten</label>
          <div *ngFor="let msg of thread.messages | slice:1" class="ticket_answer_wrapper">
            <div class="ticket_details_body">
              <img class="user_img_container"
                [ngClass]="msg.user?.picture ? 'user_img' : 'user_img_placeholder'"
                [src]="msg.user?.picture ? msg.user.picture : '../../assets/userimageplaceholder.svg'">
              <div class="ticket_details_body_main_answer">
                <div class="ticket_details_answer_header">
                  <label class="lbl_bold">{{msg.user!.firstName}} {{msg.user!.lastName}}</label>
                  <label >{{msg.timestamp | date: 'dd. MMM YYYY, HH:mm'}}</label>
                </div>
                <div class="lbl_customer_data_box_data lbl_message" [innerHTML]="msg.message"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div></div>
      <app-custom-mark-input class="mark_input"
        [data]="users"
        [btn_name]="'Antworten'"
        (selectionCreateEmitter)="createMessage($event)"
      ></app-custom-mark-input>
    </div>
  </div>
</div> -->
