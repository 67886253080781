<div *ngIf="sectionRow.type == 'link'" class="action_hover_object" (click)="openLink(sectionRow.data_value)">
  <img class="action_hover_icon" src="./assets/link.svg">
  <label class="lbl_color"><span class="lbl_light">{{sectionRow.title}}</span>{{sectionRow.data_value}}</label>
</div>

<div *ngIf="sectionRow.type == 'string'" class="action_hover_object">
  <label class="lbl_color"><span class="lbl_light">{{sectionRow.title}}</span>{{sectionRow.data_value}}</label>
</div>

<div *ngIf="sectionRow.type == 'date'" class="action_hover_object" (click)="copyText(sectionRow.data_value)">
  <img class="action_hover_icon" src="./assets/copy.svg">
  <label class="lbl_color"><span class="lbl_light">{{sectionRow.title}}</span>{{sectionRow.data_value | date: 'dd.MM.yyyy'}}</label>
</div>

<div *ngIf="sectionRow.type == 'number'" class="action_hover_object" (click)="copyText(sectionRow.data_value)">
  <label class="lbl_color"><span class="lbl_light">{{sectionRow.title}}</span>{{sectionRow.data_value}}</label>
</div>

<div *ngIf="sectionRow.type == 'Stundensatz'" class="action_hover_object">
  <label class="lbl_color"><span class="lbl_light">{{sectionRow.title}}</span>{{sectionRow.data_value}}</label>
</div>

<div *ngIf="sectionRow.type == 'password'" class="value_hover_password"  (click)="copyText(sectionRow.data_value)">
  <img class="action_hover_icon" src="./assets/copy.svg">
  <label *ngIf="showPassword" class="lbl_color">
    <span class="lbl_light">{{sectionRow.title}}</span>
    {{sectionRow.data_value}}
  </label>
  <label *ngIf="!showPassword" class="lbl_color">
    <span class="lbl_light">{{sectionRow.title}}</span>
    ••••••••••••••••••••
  </label>
  <img class="icon_eye" [src]="showPassword ? './assets/inputhide.svg' : './assets/inputshow.svg'" (click)="showPassword = !showPassword">
</div>
