<div id="action_menu">
  <img class="action_menu_button_icon" src="../../../assets/navdots.svg">
  <div id="action_menu_box">
    <div class="action" (click)="editEmitter.emit()">
      <svg xmlns="http://www.w3.org/2000/svg" width="12.046" height="12.045" viewBox="0 0 12.046 12.045">
        <g class="action_icon" transform="translate(-806 -144.477)">
          <path class="action_menu_icon" id="Pfad_943" data-name="Pfad 943" d="M7.192,124.8.1,131.893a.334.334,0,0,0-.1.236v1.359a.365.365,0,0,0,.365.365H1.723a.334.334,0,0,0,.236-.1l7.094-7.094a.194.194,0,0,0,0-.275L7.467,124.8A.194.194,0,0,0,7.192,124.8Z" transform="translate(806 22.669)" fill="#1166B2"/>
          <path class="action_menu_icon" id="Pfad_944" data-name="Pfad 944" d="M348.757.383a1.317,1.317,0,0,0-1.862,0l-1.619,1.622a.194.194,0,0,0,0,.275l1.587,1.587a.194.194,0,0,0,.275,0l1.619-1.619a1.317,1.317,0,0,0,0-1.862Z" transform="translate(468.903 144.479)" fill="#1166B2"/>
        </g>
      </svg>
      <label>Bearbeiten</label>
    </div>
    <div class="action" (click)="selectionCreateTicket.emit()">
      <svg xmlns="http://www.w3.org/2000/svg" width="6.14" height="6.14" viewBox="0 0 6.14 6.14">
        <g class="action_icon" transform="translate(5.64 0.5) rotate(90)">
          <path id="Linie_2" data-name="Linie 2" d="M0,5.64a.5.5,0,0,1-.5-.5V0A.5.5,0,0,1,0-.5.5.5,0,0,1,.5,0V5.14A.5.5,0,0,1,0,5.64Z" transform="translate(2.57)" fill="#3b88cc"/>
          <path id="Linie_3" data-name="Linie 3" d="M0,5.64a.5.5,0,0,1-.5-.5V0A.5.5,0,0,1,0-.5.5.5,0,0,1,.5,0V5.14A.5.5,0,0,1,0,5.64Z" transform="translate(5.14 2.57) rotate(90)" fill="#3b88cc"/>
        </g>
      </svg>
      <label>Ticket erstellen</label>
    </div>
    <div class="action" (click)="createProjectEmitter.emit()">
      <svg xmlns="http://www.w3.org/2000/svg" width="6.14" height="6.14" viewBox="0 0 6.14 6.14">
        <g class="action_icon" transform="translate(5.64 0.5) rotate(90)">
          <path id="Linie_2" data-name="Linie 2" d="M0,5.64a.5.5,0,0,1-.5-.5V0A.5.5,0,0,1,0-.5.5.5,0,0,1,.5,0V5.14A.5.5,0,0,1,0,5.64Z" transform="translate(2.57)" fill="#3b88cc"/>
          <path id="Linie_3" data-name="Linie 3" d="M0,5.64a.5.5,0,0,1-.5-.5V0A.5.5,0,0,1,0-.5.5.5,0,0,1,.5,0V5.14A.5.5,0,0,1,0,5.64Z" transform="translate(5.14 2.57) rotate(90)" fill="#3b88cc"/>
        </g>
      </svg>
      <label>Projekt erstellen</label>
    </div>
    <div class="action" *ngIf="false" (click)="null">
      <svg xmlns="http://www.w3.org/2000/svg" width="11.667" height="14" viewBox="0 0 11.667 14">
        <path class="action_icon" d="M53.75,2.333H51.942A2.922,2.922,0,0,0,49.084,0H47.917a2.922,2.922,0,0,0-2.858,2.333H43.25a.583.583,0,0,0,0,1.167h.583v7.583A2.92,2.92,0,0,0,46.75,14h3.5a2.92,2.92,0,0,0,2.917-2.917V3.5h.583a.583.583,0,0,0,0-1.167ZM47.917,9.917a.583.583,0,0,1-1.167,0v-3.5a.583.583,0,0,1,1.167,0v3.5Zm2.333,0a.583.583,0,0,1-1.167,0v-3.5a.583.583,0,0,1,1.167,0ZM46.267,2.333a1.754,1.754,0,0,1,1.65-1.167h1.167a1.754,1.754,0,0,1,1.65,1.167Z" transform="translate(-42.667)" fill="#3b88cc"/>
      </svg>
      <label>Löschen</label>
    </div>
  </div>
</div>
