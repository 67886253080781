<div class="dialog_view">
    <div class="dialog_window">
        <button class="dialog_window_btn_close" (click)="close()"><img src="../../../../assets/negativewhite.svg"></button>
        <div>
            <div class="dialog_window_header">
                <h2>Erweiterte Filter</h2>
                <img class="icon_contact_remove" (click)="resetFilterForm()" src="../../../../../assets/delete.svg">
            </div>
            <div class="dialog_window_body">
                <form [formGroup]="formGroup">
                  <label id="ticket_typ_lbl">Zuordnung</label>
                  <input formControlName="ticket_typ" type="hidden" [required]="submitted && formGroup.controls['ticket_typ'].invalid">
                  <ul class="ticket-types-filter">
                      <li *ngFor="let type of selectFilterTypes">
                        <button 
                          type="button"
                          [ngClass]="{'invalid': submitted && formGroup.controls['ticket_typ'].invalid}" 
                          [class.selected]="selectedFilterType.includes(type.value)"
                          (click)="toggleTypeFilter(type)">
                          <span>{{ type.label }}</span>
                        </button>
                      </li>
                    </ul>
                  <label id="status_lbl">Status</label>
                  <input formControlName="status" type="hidden" [required]="submitted && formGroup.controls['status'].invalid">
                  <ul class="ticket-status-filter">
                      <li *ngFor="let status of selectFilterStatus">
                        <button 
                          type="button" 
                          [ngClass]="{'invalid': submitted && formGroup.controls['ticket_typ'].invalid}"
                          [class.selected]="status.value == selectedFilterStatus"
                          (click)="toggleStatusFilter(status)">
                          <span>{{ status.label }}</span>
                        </button>
                      </li>
                    </ul>
                  <label>Zuweisung</label>
                  <div class="flex_slider">
                      <div class="slider_container" (click)="toggleSubscribedFilter()">
                        <div class="slider_still" [ngClass]="isSubscribed ? '' : 'slider_still_on'"></div>
                        <div class="slider_move" [ngClass]="isSubscribed ? 'slider_on' : 'slider_off'"></div>
                      </div>
                      <input formControlName="only_subscribed" type="hidden">
                      <div class="lbl_regular_12" style="margin-bottom: 0;">Nur meine Tickets</div>
                  </div>
                </form>
              </div>
        </div>
        <button class="dialog_window_btn_submit" (click)="submitFilterForm()">Filtern</button>
    </div>
</div>
  