import { ContactPerson } from "../ContactPerson";
import { TitleFactory } from "./TitleFactory";

export class ContactPersonFactory {
  static jsonFactory(rawBody: any): ContactPerson[] {
    const customers: ContactPerson[] = [];
    for(let data of rawBody){
        customers.push(this.jsonFactoryOne(data));
    }
    return customers;
  }

    static jsonFactoryOne(raw: any): ContactPerson {
        return new ContactPerson(
            raw.contact_person_id,
            raw.is_polite,
            raw.is_male,
            raw.first_name,
            raw.last_name,
            raw.phone,
            raw.email,
            raw.title ? TitleFactory.jsonFactoryOne(raw.title) : undefined,
            raw.position,
            raw.bill_receiver ? raw.bill_receiver : false,
            raw.billing_email,
        );
    }
}
