<div class="dialog_view">
  <div class="dialog_window">
    <button class="dialog_window_btn_close" (click)="closeEmitter.emit()"><img src="../../../../assets/negativewhite.svg"></button>
    <div>
      <div class="dialog_window_header">
        <h2>Wiederherstellen</h2>
      </div>
      <div class="dialog_window_body">
        <label class="lbl_regular_14">Wiederherstellungstyp wählen.</label>
      </div>
    </div>
    <div class="dialog_footer">
      <button class="dialog_window_btn_submit button_left" (click)="selectionRestoreEmitter.emit(1)">Kunde</button>
      <button class="dialog_window_btn_submit button_right" (click)="selectionRestoreEmitter.emit(2)">Interessent</button>
    </div>
  </div>
</div>
