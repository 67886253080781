import { States, StatesColorClass } from "src/app/helper/Enums";
import { EnumHelper } from "../../helper/EnumHelper";
import { ProjectType } from "./ProjectType";

export class AworkProject {
  constructor(
    public id: string,
    public name: string,
    public description: string,
    public project_typ: ProjectType,
    public project_template_id: string,
    public tags: Tag[],
    public teams: Team[],
    public status?: string,
    public status_name?: string,
    public budget?: number,
    public hours_used?: number,
  ){}

  getWorkPercentage(): number {
    let p = this.hours_used / (this.budget / 100 );
    return p ? p : 0;
  }
  getStatusColorClass(): string {
    return StatesColorClass[this.status];
  }
}

export class Tag {
  constructor(
    public name: string,
    public colour: string,
  ){}
}

export class Team {
  constructor(
    public team_id: string,
    public name: string,
  ){}
}
