import { User } from "../User";

export class UserFactory {
    static jsonFactory(rawBody: any): User[] {
        const users: User[] = [];
        for(let rawUser of rawBody){
            users.push(this.jsonFactoryOne(rawUser));
        }
        return users;
    }

    static jsonFactoryOne(rawUser: any): User {
        return new User(
            rawUser.user_id,
            rawUser.first_name,
            rawUser.last_name,
            rawUser.email,
            rawUser.phone,
            rawUser.picture,
            rawUser.authorization,
            rawUser.is_seller
        )
    }
}