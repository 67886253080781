import { AworkProject, Tag, Team } from "../AworkProject";
import { ProjectTypeFactory } from "./ProjectTypeFactory";

export class AworkPojectFactory {
  static jsonFactory(rawBody: any): AworkProject[] {
    const objects: AworkProject[] = [];
    for(let rawData of rawBody){
      objects.push(this.jsonFactoryOne(rawData));
    }
    return objects;
 }

 static jsonFactoryOne(raw: any): AworkProject {
    const req = new AworkProject(
      raw.id,
      raw.name,
      raw.description,
      ProjectTypeFactory.jsonFactoryOne(raw.projectTyp),
      raw.project_template_id,
      TagFactory.jsonFactory(raw.tags),
      TeamFactory.jsonFactory(raw.teams),
      raw.status,
      raw.statusName,
      raw.budget,
      raw.hours_used,
    );
    return req;
  }
}

export class TagFactory {
  static jsonFactory(rawBody: any): Tag[] {
    const objects: Tag[] = [];
    for(let rawData of rawBody){
      objects.push(this.jsonFactoryOne(rawData));
    }
    return objects;
 }

 static jsonFactoryOne(raw: any): Tag {
    const req = new Tag(
      raw.name,
      raw.colour,
    );
    return req;
  }
}

export class TeamFactory {
  static jsonFactory(rawBody: any): Team[] {
    const objects: Team[] = [];
    for(let rawData of rawBody){
      objects.push(this.jsonFactoryOne(rawData));
    }
    return objects;
 }

 static jsonFactoryOne(raw: any): Team {
    const req = new Team(
      raw.team_id,
      raw.name,
    );
    return req;
  }
}
