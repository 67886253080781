import { Customer } from 'src/app/buisness-object/customer/Customer';
import { States, StatesColorClass, UsedCapicityColorClass } from "src/app/helper/Enums";
import { BillngAddress } from '../customer/BillngAddress';
import { ContactPerson } from "../customer/ContactPerson";
import { Thread } from "../thread/Thread";
import { AworkProject } from "./AworkProject";
import { Section } from './Section';

export class Project {
  public customer: Customer;
  public contact_persons: ContactPerson[] = [];
  public threads: Thread[] = [];
  constructor(
    public project_id: number,
    public customer_id: number,
    public billing_address: BillngAddress,
    public contact_person_ids: number[],
    public sections: Section[],
    public awork_project: AworkProject,
    public status: number,
    public creation_date: number,
    public contract_start_date: number
  ){
  }

  getCapicityColorClass(): string {
    return UsedCapicityColorClass[this.awork_project.hours_used >= 90 ? 3 : (this.awork_project.hours_used >= 70 ? 2 : 1)];
  }

  getJSON(): any {
    let tags = [];
    this.awork_project.tags.forEach(element => {
      tags.push({
        "name": element.name,
        "colour": element.colour,
      })
    });
    let contact_persons = [];
    this.contact_persons.forEach(contact => {
      contact_persons.push({"contact_person_id": contact.contact_id})
    });
    if(this.billing_address?.contact_person && contact_persons.findIndex(c => c.contact_person_id == this.billing_address?.contact_person.contact_id) == -1){
      contact_persons.push({"contact_person_id": this.billing_address.contact_person.contact_id});
    }
    let sections = [];
    this.sections.forEach(section => {
      let sectionRows = [];
      section.section_rows.forEach(row => {
        sectionRows.push({
          "section_row_id" : row.section_row_id,
          "title" : row.title,
          "data_value": row.data_value?.length > 0 || Number(row.data_value) > 0 ? row.data_value : null
        })
      });
      sections.push({
        "section_id": section.section_id,
        "name": section.section_name,
        "section_rows": sectionRows,
      })
    });
    let body;

    /*
    body = {
      "project_id" : this.project_id,
      "customer_id" : this.customer_id,
      "name": this.awork_project.name,
      "description": this.awork_project.description,
      "projectTypeId": this.awork_project.project_typ.typ_id,
      "tags" : tags,
      "billing_address" : this.billing_address ? this.billing_address.getJSON() : null,
      "contact_persons": contact_persons,
      "sections": sections,
      "status": this.status,
      "contract_start_date": this.contract_start_date,
      "creation_date": this.creation_date ? this.creation_date : new Date().getTime()
    }
    */

    if(this.billing_address){
      body = {
        "project_id" : this.project_id,
        "customer_id" : this.customer_id,
        "name": this.awork_project.name,
        "description": this.awork_project.description,
        "projectTypeId": this.awork_project.project_typ.typ_id,
        "tags" : tags,
        "billing_address" : this.billing_address ? this.billing_address.getJSON() : null,
        "contact_persons": contact_persons,
        "sections": sections,
        "status": this.status,
        "contract_start_date": this.contract_start_date,
        "creation_date": this.creation_date ? this.creation_date : new Date().getTime()
      }
    } else {
      body = {
        "project_id" : this.project_id,
        "customer_id" : this.customer_id,
        "name": this.awork_project.name,
        "description": this.awork_project.description,
        "projectTypeId": this.awork_project.project_typ.typ_id,
        "tags" : tags,
        "contact_persons": contact_persons,
        "sections": sections,
        "status": this.status,
        "contract_start_date": this.contract_start_date,
        "creation_date": this.creation_date ? this.creation_date : new Date().getTime()
      }
    }
    return body;
  }

  getJSONUpdate(): any {
    let tags = [];
    this.awork_project.tags.forEach(element => {
      tags.push({
        "name": element.name,
        "colour": element.colour,
      })
    });
    let contact_persons = [];
    this.contact_persons.forEach(contact => {
      contact_persons.push({"contact_person_id": contact.contact_id})
    });
    if(this.billing_address?.contact_person && contact_persons.findIndex(c => c.contact_person_id == this.billing_address?.contact_person.contact_id) == -1){
      contact_persons.push({"contact_person_id": this.billing_address.contact_person.contact_id});
    }
    let sections = [];
    this.sections.forEach(section => {
      let sectionRows = [];
      section.section_rows.forEach(row => {
        sectionRows.push({
          "section_row_id" : row.section_row_id,
          "title" : row.title,
          "data_value": row.data_value?.length > 0 || Number(row.data_value) > 0 ? row.data_value : null
        })
      });
      sections.push({
        "section_id": section.section_id,
        "name": section.section_name,
        "section_rows": sectionRows,
      })
    });
    let body;
    if(this.billing_address){
      body = {
        "ProjectId" : this.project_id,
        "customer_id" : this.customer_id,
        "name": this.awork_project.name,
        "description": this.awork_project.description,
        "projectTypeId": this.awork_project.project_typ.typ_id,
        "tags" : tags,
        "billing_address" : this.billing_address ? this.billing_address.getJSON() : null,
        "contact_persons": contact_persons,
        "sections": sections,
        "status": this.status,
        "contract_start_date": this.contract_start_date,
        "creation_date": this.creation_date ? this.creation_date : new Date().getTime()
      }
    } else {
      body = {
        "ProjectId" : this.project_id,
        "customer_id" : this.customer_id,
        "name": this.awork_project.name,
        "description": this.awork_project.description,
        "projectTypeId": this.awork_project.project_typ.typ_id,
        "tags" : tags,
        "contact_persons": contact_persons,
        "sections": sections,
        "status": this.status,
        "contract_start_date": this.contract_start_date,
        "creation_date": this.creation_date ? this.creation_date : new Date().getTime()
      }
    }
    return body;
  }

  openInAwork() {
    window.open('https://orange-strong-minds.awork.com/projects/' + this.awork_project.id + '/details', '_blank')?.focus();
    //https://orange-strong-minds.awork.io/projects/c4954716-0498-ed11-bf7a-38563d6e68b8/details
  }
}

