<div class="dialog_view">
  <div class="dialog_window">
    <button class="dialog_window_btn_close" (click)="close()"><img src="../../../../assets/negativewhite.svg"></button>
    <div>
      <div class="dialog_window_header">
        <h2>Letzten Termine</h2>
      </div>
      <div class="dialog_window_body">
        <div *ngFor="let date of dates">
          <label for="">{{date | date: 'dd. MMM yyyy'}}</label>
        </div>
      </div>
    </div>
  </div>
</div>
