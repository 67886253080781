import { Component, EventEmitter, OnInit, Output, OnDestroy, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { Customer } from 'src/app/buisness-object/customer/Customer';
import { Project } from 'src/app/buisness-object/project/Project';
import { Message } from 'src/app/buisness-object/thread/Message';
import { Thread } from 'src/app/buisness-object/thread/Thread';
import { CustomerService } from 'src/app/service/customer/customer.service';
import { ProjectService } from 'src/app/service/project/project.service';
import { ThreadService } from 'src/app/service/thread/thread.service';

@Component({
  selector: 'app-dialog-search',
  templateUrl: './dialog-search.component.html',
  styleUrls: ['./dialog-search.component.scss']
})
export class DialogSearchComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() closeEmitter = new EventEmitter<undefined>();
  public customers: Customer[] = [];
  public projects: Project[] = [];
  public tickets: Thread[] = [];
  public objects: any[] = [];
  public filteredObjects: any[] = [];
  public inputSearchTimeout: any;
  public filter = {
    'all': true,
    'customer': false,
    'project': false,
    'contact': false,
    'ticket': false
  };
  public searchValue: string = '';

  constructor(
    private cService: CustomerService,
    private pService: ProjectService,
    private tService: ThreadService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    document.addEventListener('keydown', event => {
      if(event.key == 'Escape'){
        this.close();
      }
    });
    navigator.clipboard.readText().then(clipText => { this.searchValue = clipText; });
    if(this.searchValue != null && this.searchValue.length > 1){
      let input = document.getElementById("search_input_overval") as HTMLInputElement;
      if(input) input.value = this.searchValue;
      this.searchAction(this.searchValue);
    }
    this.getDataServerside();
  }

  ngAfterViewInit(): void {
    const input = document.getElementById("search_input_overval");
    if(input) input.focus();
  }

  ngOnDestroy(): void {
    if(this.inputSearchTimeout) clearTimeout(this.inputSearchTimeout);
  }

  getDataServerside() {
    let reloadTickets = this.tService.tickets$.getValue() ? false : true;
    forkJoin({
      customers: this.cService.getCustomers(false),
      projects: this.pService.getAllProjects(false),
      tickets: this.tService.getAllThreads(reloadTickets)
    }).subscribe((result) => {
      if(result){
        this.customers = result.customers;
        this.projects = result.projects;
        this.tickets = result.tickets;
        this.objects = this.customers;
        this.objects = this.objects.concat(this.projects);
        this.objects = this.objects.concat(this.tickets);
        if(this.searchValue != null && this.searchValue.length > 1){
          let input = document.getElementById("search_input_overval") as HTMLInputElement;
          if(input) input.value = this.searchValue;
          this.searchAction(this.searchValue);
        }
      }
    })
  }

  selectionFilter(f: number) {
    if(f == 1){
      this.filter.customer = !this.filter.customer;
    } else if(f == 2){
      this.filter.project = !this.filter.project;
    } else if(f == 3){
      this.filter.contact = !this.filter.contact;
    } else if(f == 4){
      this.filter.ticket = !this.filter.ticket;
    }
    if(this.filter.customer || this.filter.project || this.filter.contact || this.filter.ticket) this.filter.all = false;
    else this.filter.all = true;
    this.searchAction(this.searchValue);
  }

  searchAction(value: string) {
    this.searchValue = value;
    if(this.inputSearchTimeout) clearTimeout(this.inputSearchTimeout);
     this.inputSearchTimeout = setTimeout(() => {
      this.filteredObjects = [];
      if(value && value.length > 1){
        this.filteredObjects = this.objects.filter((object) => {
          if(object instanceof Customer && (this.filter.all || this.filter.customer)){
            if(this.searchIncludesCustomer(object, value)) return true;
            else if(this.filter.all || this.filter.contact){
              return this.searchIncludesContact(object, value);
            }
          }
          if(object instanceof Customer && (this.filter.all || this.filter.contact)) return this.searchIncludesContact(object, value);
          if(object instanceof Project && (this.filter.all || this.filter.project)) return this.searchTypProject(object, value);
          if(object instanceof Thread && (this.filter.all || this.filter.ticket)) return this.searchTypTicket(object, value);
        })
      } else {
        this.filteredObjects = [];
      }
      this.sortObjects();
     }, 400);
  }

  searchIncludesCustomer(customer: Customer, value: string): boolean {
    customer.isSearchedContact = false;
    return   customer.company_name.toLowerCase().includes(value.toLowerCase()) ||
             customer.website_url?.toLowerCase().includes(value.toLowerCase()) ||
             customer.customer_id.toString().toLowerCase().includes(value.toLowerCase()) ||
             customer.billing_address.address.postalCode?.toString().toLowerCase().includes(value.toLowerCase());
  }

  searchIncludesContact(customer: Customer, value: string): boolean {
    customer.isSearchedContact = false;
    for(let c of customer.contact_persons){
      if(c.firstName.toLowerCase().includes(value) ||
        c.lastName.toLowerCase().includes(value) ||
        c.email?.toLowerCase().includes(value) ||
      (c.firstName + ' ' + c.lastName).toLowerCase().includes(value)){
        customer.isSearchedContact = true;
        return true;
     }
      // return c.firstName.toLowerCase().includes(value) ||
      //        c.lastName.toLowerCase().includes(value) ||
      //        c.email?.toLowerCase().includes(value) ||
      //       (c.firstName + ' ' + c.lastName).toLowerCase().includes(value);
    }
    return false;
  }

  searchTypProject(project: Project, value: string): boolean {
    return project.project_id.toString().toLowerCase().includes(value.toLowerCase()) ||
            project.awork_project.description?.toLowerCase().includes(value.toLowerCase()) ||
            project.awork_project.name.toLowerCase().includes(value.toLowerCase());
  }

  searchTypTicket(ticket: Thread, value: string): boolean {
    //TODO User einbinden
    return this.messagesIncludeSearch(value, ticket.messages) ||
           ticket.threadId.toString().toLowerCase().includes(value.toLowerCase());
          // return ticket.user.firstName.toLowerCase().includes(value.toLowerCase()) ||
          // ticket.user.lastName.toLowerCase().includes(value.toLowerCase()) ||
          // (ticket.user.firstName + ' ' + ticket.user.lastName).toLowerCase().includes(value.toLowerCase()) ||
          // this.messagesIncludeSearch(value, ticket.messages);
  }
  messagesIncludeSearch(value: string, messages: Message[]): boolean {
    for(let msg of messages){
      if(msg.message.toLowerCase().includes(value.toLowerCase())) return true;
    }
    return false;
  }

  getName(object: any): string {
    if(object instanceof Customer){
      if(object.isSearchedContact){
        let contactName = '';
        for(let c of object.contact_persons){
          if(c.firstName && c.lastName) contactName += c.firstName + ' ' + c.lastName + ', ';
        }
        return contactName.substring(0, contactName.length - 2);
      } else {
        return object.company_name;
      }
    } else if(object instanceof Project) return object.awork_project.name;
    else if(object instanceof Thread) return object.getThreadTitle();
  }
  getTyp(object: any): string {
    if(object instanceof Customer){
      if(object.isSearchedContact){
        return "Kontakt";
      } else {
        return "Kunde";
      }
    } else if(object instanceof Project) return "Projekt";
    else if(object instanceof Thread) return "Ticket";
  }
  getTypColorClass(object: any): string {
    if(object instanceof Customer){
      if(object.isSearchedContact){
        return "lbl-typ btn-tab-orange";
      } else {
        return "lbl-typ btn-tab-green";
      }
    } else if(object instanceof Project) return "lbl-typ btn-tab-red";
    else if(object instanceof Thread) return "lbl-typ btn-tab-blue";
  }


  sortObjects() {

  }

  close() {
    this.closeEmitter.emit();
  }

  gotToData(object: any) {
    if(object instanceof Customer) this.router.navigate(['customers'], { queryParams: { customerId: object.customer_id } });
    else if(object instanceof Project) this.router.navigate(['customers'], { queryParams: { customerId: object.customer_id, projectId: object.project_id } });
    else if(object instanceof Thread){
      if(object.project_id != null){
        this.router.navigate(['customers'], { queryParams: { customerId: object.customerId, projectId: object.project_id, ticketId: object.threadId } });
      } else {
        this.router.navigate(['customers'], { queryParams: { customerId: object.customerId, ticketId: object.threadId } });
      }
    }
    this.closeEmitter.emit();
  }
}
