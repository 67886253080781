import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Project } from 'src/app/buisness-object/project/Project';

@Component({
  selector: 'app-dialog-project-allocation',
  templateUrl: './dialog-project-allocation.component.html',
  styleUrls: ['./dialog-project-allocation.component.scss']
})
export class DialogProjectAllocationComponent implements OnInit {
  @Input() projects!: Project[];
  @Output() closeEmitter = new EventEmitter<undefined>();
  @Output() applyFilterEmitter = new EventEmitter<Project[]>();
  public selectedProjects: Project[] = [];

  constructor() { }

  ngOnInit(): void {
  }

  selectionProject(project: Project) {
    let index = this.selectedProjects.findIndex((p: any) => p.project_id == project.project_id);
    if(index > -1) this.selectedProjects.splice(index,1);
    else this.selectedProjects.push(project);
  }
  isSelected(project: Project): boolean {
    return this.selectedProjects.findIndex((p: any) => p.project_id == project.project_id) > -1;
  }

  close() {
    this.closeEmitter.emit();
  }

  applyFilter() {
    this.applyFilterEmitter.emit(this.selectedProjects);
  }
}
