import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { finalize, timeout } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { LoadingService } from "./loading.service";

@Injectable()
export class LoadingScreenInterceptor implements HttpInterceptor{
    activeRequest = 0;

    constructor(
      private loadingScreenService: LoadingService
    ){}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if(this.activeRequest === 0){
          if(request.url != (environment.api + '/back/thread/notification')) {
            this.loadingScreenService.startLoading();
          }
        }
        this.activeRequest++;
        return next.handle(request).pipe(
            finalize(() => {
                this.activeRequest--;
                if(this.activeRequest === 0){
                    this.loadingScreenService.stopLoading();
                }
            })
        )
    }
}
