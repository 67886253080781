<div class="dialog_view">
  <div class="dialog_window">
    <button class="dialog_window_btn_close" (click)="close()"><img src="../../../../assets/negativewhite.svg"></button>
    <div>
      <div class="dialog_window_header">
        <h2>Kundenstatus</h2>
      </div>
      <div class="dialog_window_body">
        <div class="checkbox_wrapper" (click)="selectionStatus(1)">
          <input type="checkbox" class="checkbox" [checked]="selectedStatus.includes(1)">
          <label>Kunde</label>
        </div>
        <div class="checkbox_wrapper" (click)="selectionStatus(2)">
          <input type="checkbox" class="checkbox" [checked]="selectedStatus.includes(2)">
          <label>Interessent</label>
        </div>
        <div class="checkbox_wrapper" (click)="selectionStatus(3)">
          <input type="checkbox" class="checkbox" [checked]="selectedStatus.includes(3)">
          <label>Archiviert</label>
        </div>
      </div>
    </div>
    <button class="dialog_window_btn_submit" (click)="applyFilter()">Filtern</button>
  </div>
</div>
