import { Address } from "../Address";
import { ContactPersonFactory } from "./ContactPersonFactory";

export class AddressFactory {
    static jsonFactoryOne(rawAddress: any): Address {
        return new Address(
            rawAddress.street,
            rawAddress.postal_code,
            rawAddress.city,
            rawAddress.company_name,
            rawAddress.atu,
            rawAddress.contact ? ContactPersonFactory.jsonFactoryOne(rawAddress.contact) : undefined
        );
    }
}
