<a class="sidebar_customer_element" (click)="openMobileMenu()" [ngClass]="customer.customer_id == paraCustomerId ? 'sidebar_customer_element_active' : ''" routerLink="/customers" [queryParams]="{'customerId': customer.customer_id}">
  <div class="sidebar_customer_element_header">
    <label class="lbl_light_12">WMPD {{customer.customer_id}}</label>
    <div class="sidebar_customer_element_header_inner_wrapper">
      <label class="lbl_box lbl_box_gray">{{customer.customer_typ == 1 ? 'Kunde' : (customer.customer_typ == 2 ? 'Interessent' : 'Archiviert')}}</label>
      <div *ngIf="customer.customer_typ == 1" class="date_traffic_light" [ngClass]="customer.getLastAppointmentStatus()"></div>
    </div>
  </div>
  <label class="lbl_bold_18">{{customer.company_name}}</label>
  <label class="lbl_light_12">{{customer.contact_persons[0].email}}</label>
  <label class="lbl_light_12">{{customer.contact_persons[0].phone}}</label>
  <div *ngIf="customer.website_url" class="url_wrapper">
      <label class="lbl_light_12" >{{customer.website_url}}</label>
      <img (click)="customer.openUrl()" class="img-go-to-web" src="./assets/linksymbol.svg">
  </div>
</a>
