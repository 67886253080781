import { ProjectType } from "../ProjectType";
import { TagFactory } from "./AworkPojectFactory";
import { SectionFactory, SectionRowFactory } from "./SectionFactory";


export class ProjectTypeFactory {
  static jsonFactory(rawBody: any): ProjectType[] {
    const objects: ProjectType[] = [];
    for(let rawData of rawBody){
      objects.push(this.jsonFactoryOne(rawData));
    }
    return objects;
 }

 static jsonFactoryOne(raw: any): ProjectType {
    const req = new ProjectType(
      raw.typ_id,
      raw.typ_name,
      SectionFactory.jsonFactory(raw.sections),
      raw.tags ? TagFactory.jsonFactory(raw.tags) : []
    );
    return req;
  }
}
