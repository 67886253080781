<div class="view_body_create" [formGroup]="form">
  <div class="create_header">
    <h1>Projekt {{this.form.controls['project_id'].value != 0 ? 'bearbeiten' : 'erstellen'}}</h1>
    <div class="create_button_wrapper">
      <button class="btn_cancel" (click)="cancelCreateAction()">Abbrechen</button>
      <button class="btn_tab btn_tab_blue_light" (click)="this.selectedProject ? selectionUpdate() : selectionCreate()">{{this.selectedProject ? 'Speichern' : 'Erstellen'}}</button>
    </div>
  </div>
  <div class="create_container">
    <div class="column">
      <div class="data_box">
        <div class="data_box_package data_box_package_last">
          <h2>Projekt</h2>
          <div class="input_wrapper">
            <label class="lbl_regular_12">Projekttyp *</label>
            <div #dropdown_1 class="dropdown_wrapper" [class.dropdown_wrapper_disabled]="selectedProject" (click)="showDropdowOne = !showDropdowOne" appDropdown [show]="showDropdowOne" (changedValueEmitter)="showDropdowOne = $event">
              <input #inputTyp class="dropdown_btn lbl_dropdown_small" placeholder="Auswählen..." [required]="submitted && form.controls['project_typ'].invalid" data-readonly [disabled]="selectedProject">
              <img *ngIf="!selectedProject" class="dropdown_icon" [ngClass]="showDropdowOne ? 'dropdown_icon_rotate' : ''" src="../../../../assets/arrowdropdown.svg">
              <div *ngIf="showDropdowOne" class="dropdown_value_container">
                <div class="dropdown_value_container_scroll_wrapper">
                  <div *ngFor="let typ of projectTyps" (click)="selectionTyp(typ); inputTyp.value = typ.typ_name;" class="dropdown_element lbl_dropdown_small">{{typ.typ_name}}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="input_wrapper">
            <label class="lbl_regular_12">Projektname *</label>
            <input type="text" placeholder="Name des Projekts" formControlName="project_name" [required]="submitted && this.form.controls['project_name'].invalid">
          </div>
          <div class="input_wrapper">
            <div class="counter-wrapper">
              <label class="lbl_regular_12">Projektbeschreibung</label>
              <label>({{description_char_count}} Zeichen übrig)</label>
            </div>
            <textarea placeholder="Projektbeschreibung" maxlength="250" formControlName="project_description" [class.input_invalid]="submitted && this.form.controls['project_description'].invalid"></textarea>
          </div>
          <div class="input_wrapper">
            <label class="lbl_regular_12">Vetragsbeginn *</label>
            <input #inputDate type="date" formControlName="contract_start_date" [class.date-input--has-value]="inputDate.value != null" [class.input_invalid]="submitted && form.controls['contract_start_date'].invalid">
          </div>
        </div>
      </div>
      <button *ngIf="this.form.controls['contacts'].value?.length == 0" class="btn_add_field" (click)="showDialogContacts = true"><img class="icon_add" src="../../../../assets/addelement.svg"> Anprecheperson hintzufügen</button>
      <div class="data_box" *ngIf="this.form.controls['contacts'].value?.length != 0">
        <div class="data_box_package" style="border: none;">
          <h2>Ansprechpersonen</h2>
          <div class="data_box_package_inner_flex">
            <label *ngFor="let contact of form.controls['contacts'].value; let i = index" class="lbl_box lbl_box_gray">
              <img class="lbl_box_btn_icon" src="../../../../assets/removetag.svg"  (click)="removeContact(contact, i)">
              {{contact.firstName[0]}}. {{contact.lastName}}
            </label>
            <label class="lbl_box lbl_box_blue_light" style="cursor: pointer;" (click)="showDialogContacts = true">
              <img class="lbl_box_btn_icon" src="../../../../assets/addelement.svg">
              Hinzufügen
            </label>
          </div>
        </div>
      </div>
      <app-customer-create-different-address *ngIf="this.form.controls['add_different_address'].value == true"
          [form]="form"
          [submitted]="submitted"
          [contacts]="contacts"
          (removeDifferentAddressEmitter)="removeDifferentAddressEmitter.emit($event)"
          (changeEmitter)="this.changeDetected = true"
       ></app-customer-create-different-address>
       <button *ngIf="this.form.controls['add_different_address'].value == false"
        class="btn_add_field"
        (click)="selectionAddDifferentAddress()">
        <img class="icon_add" src="../../../../assets/addelement.svg">
        Abweichende Rechnungsadresse hinzufügen
      </button>
    </div>
    <div class="column">
      <div class="data_box">
        <div class="data_box_package data_box_package_last" style="border: none; min-height: 150px;">
          <div id="typ_disabled" class="data_box_disabled">
            <label class="lbl_color_small">Bitte wähle einen Projekttyp um weitere Angaben zum Projekt zu machen</label>
          </div>
          <h2>Zusatzanforderungen</h2>
          <label *ngIf="form.controls['project_typ'].value?.tags.length == 0">Keine Zusatzanforderungen</label>
          <div class="tags_wrapper" *ngIf="this.form.controls['project_typ'].value != null && form.controls['project_typ'].value.tags.length > 0">
            <div *ngFor="let tag of form.controls['project_typ'].value.tags">
              <label class="lbl_box" style="cursor: pointer;" [ngClass]="tagIsSelected(tag) ? getColor(tag.colour) : 'lbl_box_gray'" (click)="selectionTag(tag)">{{tag.name}}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="column">
      <app-section *ngFor="let infoForm of form.controls['sections'].value; let i = index; let last = last" style="width: 100%"
            [form]="infoForm"
            [submitted]="submitted"
            [last]="last"
            [index]="i"
            (changeEmitter)="changeDetected = true"
      ></app-section>
      <div id="typ_disabled_2" class="data_box">
        <div class="data_box_package data_box_package_last">
          <div class="data_box_disabled data_box_height">
            <label class="lbl_color_small">Bitte wähle einen Projekttyp um weitere Angaben zum Projekt zu machen</label>
          </div>
        </div>
      </div>
      <!-- <button class="btn_add_field" (click)="addSectionForm()"><img class="icon_add" src="../../../../assets/addelement.svg"> Entwurf hintzufügen</button> -->
    </div>
    <div class="column">
      <div class="data_box">
        <div class="data_box_package data_box_package_last">
          <div id="typ_disabled_3" class="data_box_disabled">
            <label class="lbl_color_small">Derzeit nicht verfügbar.</label>
          </div>
          <h2>Infos und Aufgaben vorab bestimmen</h2>
          <div class="data_box_package data_box_package_last">
            <div class="footer_btn_wrapper" (click)="null">
              <img class="btn_label_icon" src="../../../assets/addelement.svg">
              <button class="btn_label">Neues Ticket erstellen</button>
            </div>
            <div class="footer_btn_wrapper" (click)="null">
              <img class="btn_label_icon" src="../../../assets/addelement.svg">
              <button class="btn_label">Neue Dokumentation</button>
            </div>
            <div class="footer_btn_wrapper" (click)="null">
              <img class="btn_label_icon" src="../../../assets/addelement.svg">
              <button class="btn_label">Neue Erinnerung</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-dialog-contact *ngIf="showDialogContacts"
  [contacts]="contacts"
  [selectedContacts]="form.controls['contacts'].value"
  (closeEmitter)="showDialogContacts = false"
  (submitEmitter)="form.controls['contacts'].setValue($event); showDialogContacts = false"
></app-dialog-contact>
