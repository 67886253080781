import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Customer } from 'src/app/buisness-object/customer/Customer';
import { Project } from 'src/app/buisness-object/project/Project';

@Component({
  selector: 'app-action-menu',
  templateUrl: './action-menu.component.html',
  styleUrls: ['./action-menu.component.scss']
})
export class ActionMenuComponent implements OnInit {
  @Output() editEmitter = new EventEmitter<undefined>();
  @Output() createProjectEmitter = new EventEmitter<undefined>();
  @Output() selectionProjectEmitter = new EventEmitter<undefined>();
  @Output() selectionCreateTicket = new EventEmitter<undefined>();

  constructor() { }

  ngOnInit(): void {
  }
}
