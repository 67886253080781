import { ContactPerson } from "./ContactPerson";

export class Address {
    constructor(
        public street: string,
        public postalCode: string,
        public city: string,
        public company_name?: string,
        public atu?: string,
        public contact_person?: ContactPerson,
    ){}
}
