import { FormBuilder, FormGroup, Validators } from "@angular/forms";

export class TicketForm {

  public static getTicketForm(formBuilder: FormBuilder): FormGroup {
    let form = formBuilder.group({
      subject: [null, [Validators.required, Validators.minLength(2)]],
      message: [null, [Validators.required, Validators.minLength(2)]],
      thread_typ: [1,Validators.required]
    });
    return form;
  }
}
