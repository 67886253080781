<div class="view_body">
  <div id="tickets_header">
    <div class="breadcrumbs_wrapper">
      <h1 [ngStyle]="{'cursor': (selectedThread || surface == 2) ? 'pointer' : ''}" (click)="(selectedThread || surface == 2) ? closeThread() : null">Tickets</h1>
      <img *ngIf="selectedThread || surface == 2" class="icon_breadcrum" src="../../../assets/navarrow.svg">
      <h1 *ngIf="surface == 1" (click)="goToCustomer()" style="cursor: pointer;">{{selectedThread ? selectedThread.customer!.company_name : ''}}</h1>
      <h1 *ngIf="surface == 2">Erstellen</h1>
    </div>
    <div *ngIf="surface == 2 && routeCustomerId">
      <button class="btn_flex_box" (click)="goToCustomer()">
        <img class="btn_action_icon" style="transform: rotate(-90deg);" src="../../../assets/navarrow.svg">
        <label>zurück zum Kunden</label>
      </button>
    </div>
  </div>
  <div *ngIf="surface == 1 && !selectedThread" style="width: 100%;">
    <div id="header_container">
      <div class="header_one_wrapper">
        <div class="input_wrapper">
          <div class="dropdown_wrapper" (click)="showDropdowOne = !showDropdowOne" appDropdown [show]="showDropdowOne" (changedValueEmitter)="showDropdowOne = $event">
            <input #inputFilter placeholder="Auswählen..." data-readonly>
            <img class="dropdown_icon" [ngClass]="showDropdowOne ? 'dropdown_icon_open' : ''" src="../../../../assets/arrowdropdown.svg">
            <div class="dropdown_value_container" *ngIf="showDropdowOne">
              <label (click)="changeFilterTyp(1); inputFilter.value = 'Offene Tickets'" class="dropdown_element lbl_dropdown_small">Offene Tickets</label>
              <label (click)="changeFilterTyp(2); inputFilter.value = 'Gelöste Tickets'" class="dropdown_element lbl_dropdown_small">Gelöste Tickets</label>
            </div>
          </div>
        </div>
        <div class="filter_wrapper">
          <div class="btn_tab" [ngClass]="filter.filter_after == 1 ? 'btn_tab_blue' : 'btn_tab_blue_light'" (click)="changeFilter(1)">Neueste zuerst</div>
          <div class="btn_tab" [ngClass]="filter.filter_after == 2 ? 'btn_tab_blue' : 'btn_tab_blue_light'" (click)="changeFilter(2)">Nach Kunde</div>
          <div class="btn_tab" [ngClass]="filter.filter_after == 3 ? 'btn_tab_blue' : 'btn_tab_blue_light'" (click)="changeFilter(3)">Nach Ersteller</div>
        </div>
      </div>
      <div class="filter_wrapper">
        <input #search class="inputfield_search lbl_input" type="text" placeholder="Tickets durchsuchen" (input)="selectionSearch(search.value)">
        <!-- <label class="btn_tab btn_tab_blue" (click)="openCreate()">Ticket erstellen</label> -->
      </div>
    </div>
    <div id="open_pins_list_container">
      <div id="list_top">
        <div class="lbl_title">{{filter.filter_after == 1 ? 'Nach neueste' : (filter.filter_after == 2 ? 'Nach Kunde' : 'Nach Ersteller')}}</div>
        <label>{{threadsFiltered.length}}</label>
      </div>
      <div id="list_header">
        <label class="lbl_regular_14">Datum / Kunde</label>
        <label class="lbl_regular_14">Ersteller</label>
        <label class="lbl_regular_14">Kommentare</label>
        <label class="lbl_regular_14">Aktionen</label>
        <label class="lbl_regular_14">Status</label>
        <label class="lbl_regular_14"></label>
      </div>
      <div *ngIf="filter.filter_after == 1" id="elements_container">
        <app-ticket-list-element *ngFor="let thread of threadsFiltered; let last = last"
            [thread]="thread"
            [last]="last"
            (openThreadEmitter)="openDetails($event)"
            (dissolveEmitter)="resolveThread($event)"
            (restoreEmitter)="restoreThread($event)"
        ></app-ticket-list-element>
      </div>
      <div *ngIf="filter.filter_after == 2" id="elements_container">
        <div *ngFor="let customerThreads of threadsSortByCustomers; let i = index;" id="{{'thread_conatiner_' + 1}}">
          <div class="list_title_inner" (click)="showListAction(i)">{{customerThreads[0].customer!.company_name}}
            <img class="list_title_inner_icon" [ngStyle]="{'transform': isShown(i) ? 'rotate(180deg)' : ''}" src="../../../assets/arrowdropdown.svg">
          </div>
          <div class="list_container" [ngClass]="isShown(i) ? 'list_close' : 'list_open'">
            <app-ticket-list-element class="list_element" *ngFor="let thread of customerThreads"
              [thread]="thread"
              (openThreadEmitter)="openDetails($event)"
              (dissolveEmitter)="resolveThread($event)"
            ></app-ticket-list-element>
          </div>
        </div>
      </div>
      <div *ngIf="filter.filter_after == 3" id="elements_container">
        <div *ngFor="let customerThreads of threadsSortByCustomers; let i = index;" id="{{'thread_conatiner_' + 1}}">
          <div class="list_title_inner" (click)="showListAction(i)">{{customerThreads[0].user!.firstName}} {{customerThreads[0].user!.lastName}}
            <img class="list_title_inner_icon" [ngStyle]="{'transform': isShown(i) ? 'rotate(180deg)' : ''}" src="../../../assets/arrowdropdown.svg">
          </div>
          <div class="list_container" [ngClass]="isShown(i) ? 'list_close' : 'list_open'">
            <app-ticket-list-element class="list_element" *ngFor="let thread of customerThreads"
              [thread]="thread"
              (openThreadEmitter)="openDetails($event)"
              (dissolveEmitter)="resolveThread($event)"
            ></app-ticket-list-element>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-ticket-details *ngIf="selectedThread" style="width: 100%;"
    [thread]="selectedThread"
    [currentUser]="currentUser"
    [users]="users"
      (closeThreadEmitter)="closeThread()"
      (dissolveEmitter)="resolveThread($event)"
      (createMessageEmitter)="createMessage($event)"
      (restoreEmitter)="restoreThread($event)"
  ></app-ticket-details>
  <app-ticket-create *ngIf="surface == 2" style="width: 100%;"
    [customers]="customers"
    [routeCustomerId]="routeCustomerId"
    [users]="users"
    [users]="users"
    (closeCreateSuccessEmitter)="closeCreate($event)"
  ></app-ticket-create>
</div>
