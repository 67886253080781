<div class="dialog_view">
  <div class="dialog_window">
    <button class="dialog_window_btn_close" (click)="close()"><img src="../../../../assets/negativewhite.svg"></button>
    <div>
      <div class="dialog_window_body">
        <div id="search_wrapper">
          <img src="../../../../assets/searchblack.svg">
          <input #search_overval type="text" name="search" id="search_input_overval" placeholder="Suchbegriff eingeben..." (input)="searchAction(search_overval.value)">
          <!-- <img src="../../../../assets/arrowdropdown-grey.svg"> -->
        </div>
        <div id="search-filter-btn-wrapper">
          <button class="btn-tab" [class.btn-tab-green]="filter.customer" (click)="selectionFilter(1)">Kundename</button>
          <button class="btn-tab" [class.btn-tab-red]="filter.project" (click)="selectionFilter(2)">Projektname</button>
          <button class="btn-tab" [class.btn-tab-orange]="filter.contact" (click)="selectionFilter(3)">Kontaktperson</button>
          <button class="btn-tab" [class.btn-tab-blue]="filter.ticket" (click)="selectionFilter(4)">Ticket</button>
        </div>
        <div id="search_result_list">
          <label class="lbl_light" style="padding: 30px 0 0 20px;" *ngIf="filteredObjects.length == 0">Keine Ergebnisse gefunden.</label>
          <div *ngFor="let object of filteredObjects" class="search_result_list_item" (click)="gotToData(object)">
            <label class="lbl-typ" [ngClass]="getTypColorClass(object)" class="lbl_light">{{getTyp(object)}}</label>
            <label [innerHTML]="getName(object)"></label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
